
import Footer from '../components/footer';
import Header from '../components/header';
import Headerfull from '../components/headerfull';
import eth from "../images/eth.svg";
import f1 from "../images/f1.png"
import f2 from "../images/footer2.png";
import f3 from "../images/footer3.svg";
import f4 from "../images/Design_Colored 16x16.svg";
import smll1 from "../images/sma2.jpg";
import Headerbeige from '../components/headerbeige';
import Footerlinksfixed from '../components/footerlinks';


export const Genius3 = () => {
    return (
        <>
            <body className='bg-[#d3d3d3] bg-gray'>

                <div className="App">
                    <Headerbeige name="John" />
                </div>

                <div className='mx-auto px-2 sm:px-4 lg:px-4 w-11/12 py-0 text-center h-screen'>
                <div className='h-full w-full flex justify-center items-center'>

                </div>
                {/* <div className="App">
                    <Footerlinksfixed name="John" />

                </div> */}

                </div>
            </body>
        </>
    )
}
export default Genius3;