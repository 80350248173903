export const ROUTES = {
    ACTUALSWAP:"/swap",
    HOME4 : "/home",
    CREDITSTEXT : "/aboutus",
    CONTACT : "/donate",
    TERMSOFSERVICES : "/termsofuse",
    PRIVACYTEXT : "/privacypolicy",
    DYOR : "/dyor",
    HELP : "/help",
    NOPAGE1 : "/notfound",   
    NOPAGE : "/404",
    CREDITSTEXT2 : "/contact",
    FINALSWAPSTEP22 : "/swapsettings",
    BUY : "/buy", 
    ANALYSE2 : "/analyse",
    ANALYSIS:"/analysis",
    ANALYSEERROR:"/analysiserror", 
    FINALSWAPSTEP32 : "/compare",
    GENIUS5COPY : "/appnc",
    APP1C : "/appc",
    POOLS : "/linear-treasures",
    SWAPSWAP : "/swap",
    TRIANGULARTREASURES : "/triangular-treasures",
    TOKENWARNING : "/tokenwarning",
    WALLET : "/wallet",
    LINEAR2 : "/linear1",
    SEALUNSEAL2 : "/sealunseal",
    TOKEN : "/token",
    ADDTOKEN : "/add-token",
    REMOTE : "/remote", 
    IMAGINARY : "/imaginary",
    NOIMAGINARY : "/noimaginary",
    IMAGINARY1 : "/imaginary1",
    NOIMAGINARY1 : "/noimaginary1",
    ADVANCEDSWAP : "/proswap",
    COMPAREONCHAIN : "/compare-onchain",
    COMPARECROSSCHAIN : "/compare-crosschain",
    TRIANGULAR1 : "/triangular1",
    PAIR : "/pair",
    ENCRYPTDECRYPT : "/encryptdecrypt",
    
    SWAPSETTINGS2 : "/swapsettings2",
    SEALUNSEAL : "/sealunseal2",
    LINEAR1 : "/linear2",
    SWAPSWAP2 : "/swapswap2",
    FINALSWAPSTEP5 : "/swapswap",
    FINALSWAPSTEP4 : "/finalswapstep4",
    ERROR : "/error",
    HOME : "/swap",
    ANALYSE:"/analyse1",
    GENIUS5 : "/genius-5",
    GENIUSWHITE : "/genius-white",
    GENIUS9 : "/genius-9",
    GENIUS3 : "/genius-3",
    MUSEUMPAGE : "/museumpage",
    ROWY : "/rowy",
    MOUSEMOVE : "/mouse-move",
    CREDIT2 : "/credit-4-copy-2",
    GENIUS33 : "/genius-33",
    GENIUS4 : "/genius-4",
    CREDIT3 : "/credit-4-copy-3",
    HOME1 : "/home1",
    CREDIT4 : "/credit-4-copy-4",
    HOME2 : "/home2",
    GENIUS5COPY2 : "/Genius-5copy2",
    HOME3 : "/home3",
    HOME3LIGHT : "/home3light",
    WALLETNOTCONNECTED: "/walletnotconnected",
    SWAP: "/swap1",
    WALLETCONNECTED: "/walletconnected",
    SWAP2: "/swap2",
    SWAP3: "/swap3",
    GENIUSDBEIGE: "/genius-beige",
    GENIUSDBEIGE2: "/genius-beige2",
    GENIUSDBEIGE3: "/genius-beige3",
    APP1NC : "/app1nc1",
    FINALSWAPSTEP2 : "/finalswapstep2",
    FINALSWAPSTEP3 : "/finalswapstep3",
    

  
}