// src/MyComponent.tsx
import React, { useEffect, useState } from 'react';
import logo from "../images/Objects Favicon.png";
import arr from "../images/arr.svg";
import eth from "../images/eth.svg";
import eth1 from "../images/ethdrop.svg";
import eth2 from "../images/ethdrop1.svg";
import ava from "../images/ava.jpeg";
import tc from "../images/tc.png";
import Dropdown1 from "../components/dropdown";
import { Dropdown } from 'flowbite';
import dai1 from "../images/dai1.svg";
import trinew from "../images/trinew.svg";
import { ConnectButton, useAccountModal, useChainModal, useConnectModal } from '@rainbow-me/rainbowkit';
import { useAccount, useBalance, useChainId, useConnect, useDisconnect, useSwitchNetwork } from 'wagmi';
import { useNavigate } from 'react-router-dom';
import Wallet from '../pages/wallet';
import bnc from '../images/eth.svg';
import btc from '../btc.png';
import con1 from "../images/argent.png";
import con2 from "../images/coinbase.png";
import con3 from "../images/metamask.png";
import con4 from "../images/walletconnect.jpg";
import con5 from "../images/trustwallet.jpeg";
import scan from "../images/scanner.jpeg";
import { WalletButton } from '@rainbow-me/rainbowkit';
import rainbow from '../images/rainbow.jpeg';
import { useWalletContext } from '../Provider/useContext';
import { ROUTES } from '../routes/routes';
import { toast } from 'react-toastify';
// import eth from "./eth.svg"
import polygon_1 from "./Polygon.png";
import ethdrop from "./ethdrop.svg";
// import ava from "./ava.jpeg";
import base1 from "../base1.svg";
import op from "../bri2.png";
import Ethereum from '../Ethereum.png';
import Fantom from '../Fantom.png';
import Fuse from '../Fuse.png';
import Gnosis from '../Gnosis.png';
import Linea from '../Linea.png';
import Moonbeam from '../Moonbeam.png';
import Moonriver from '../Moonriver.png';
import Optimism from '../Optimism.png';
import Polygon from '../Polygon.png';
import zkSync1 from '../zkSync1.png';
import arbitrium from '../Arbitrum.png';
import aurora1 from '../aurora1.png';
import avalanche1 from '../avalanche1.png';
import base_1 from '../base_1.png';
import boba_1 from '../boba_1.png';
import { fantom, optimism } from 'wagmi/dist/chains';
import { useTokenContext } from '../Provider/useContext';


interface MyComponentProps {
    name: string;
}

declare module '*.png' {
    const src: string;
}
//  custom routes for open wallet
 
interface wallet {
    isConnectModelOpen:boolean,
    isWalletConnect:boolean
}
const Headerfull: React.FC<MyComponentProps> = ({ name }) => {

    const { walletContext, setWalletContext } = useWalletContext() as any;
    const walletConextResp = useWalletContext();
    const navigate = useNavigate();
    const [search, setSearch] = useState<string>("");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [focused, setFocused] = useState(false);
    const [visibleDropDownMenu, setVisibleDropDownMenu] = useState(false);

    const [activeAccordion, setActiveAccordion] = useState(null);

    const toggleAccordion = (accordionId: any) => {
        if (activeAccordion === accordionId) {
            setActiveAccordion(null);
        } else {
            setActiveAccordion(accordionId);
        }
    };
    const { disconnect } = useDisconnect()
    const { connect } = useConnect();
    const { openConnectModal } = useConnectModal();
    const { openAccountModal } = useAccountModal();
    const { openChainModal } = useChainModal();

    const useAccount_ = useAccount();
    const useAccountBalance = useBalance({ address: useAccount_.address });

    const chainId = useChainId();
    const { chains, switchNetwork } = useSwitchNetwork();
  const { tokenContext, setTokenContext } = useTokenContext() as any;

    const networkName = (chains: any) => {
        
        const foundChain = chains.find((chains: any) => chains.id === chainId);
        if (foundChain) {
            return foundChain;
        } else {
            return '';
        }
    }


    useEffect(()=> {
        // window.location.reload();
    },[chainId])
    const networkName_1 = networkName(chains);

    const selectedChain = networkName(chains);


    const truncateAddress = (address: any, length = 4) => {
        if (!address) return '';
        
        const prefixLength = length;
        const suffixLength = length;
        const prefix = address.substring(0, prefixLength);
        const suffix = address.substring(address.length - suffixLength);
      
        return `${prefix}...${suffix}`;
    };

    const address = useAccount_.address;
    
    const useAccount_1 = {
        address: address // Replace with your actual address
    };
    
    const truncatedAddress = truncateAddress(useAccount_.address);


    const [openWallet, setWalletModel] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
  
    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    function closeWalletModel() {
        setWalletModel(false);
        const wallet: wallet = {
            isConnectModelOpen:false,
            isWalletConnect:false
        }

        setWalletContext(wallet);
    }
    useEffect(() => {
    if (useAccount_.status === 'connected') {
         
        // setIsMenuOpen_(false)
        if (openWallet) {
            setWalletModel(false);
        }
        closeModal();
    } else {

        }
        
    }, [useAccount_]);

    useEffect(() => {
        if (useAccount_.status === 'disconnected' && walletConextResp?.walletContext?.isConnectModelOpen && !openWallet) {
            openWalletModel()
        } else if(useAccount_.status === 'connected' && walletConextResp?.walletContext?.isConnectModelOpen) {
          
            const wallet: wallet = {
                isConnectModelOpen:false,
                isWalletConnect:false
            }
    
            setWalletContext(wallet);
            setWalletModel(false)
            
        } else {
            return;
        }
    },[walletConextResp,useAccount_,openWallet])

    const openWalletModel = () => {
        setWalletModel(true);
        const wallet: wallet = {
            isConnectModelOpen:true,
            isWalletConnect:false
        }

        setWalletContext(wallet);
    };
    // end


    const onSearchFocus = () => {
        setFocused(true);
        setVisibleDropDownMenu(true);
        // setVisibleDropDownMenu(true) 
        // Perform actions when the element is focused
    };

    const onFocusOut = () => {
        setFocused(false);
        setVisibleDropDownMenu(false);
        //setVisibleDropDownMenu(false)
        // Perform actions when the element is blurred
    };

    const updateSearch = (value: string) => {
        setSearch(value || "");
        setVisibleDropDownMenu(value ? true : false)

    }

    const [isMenuOpen_, setIsMenuOpen_] = useState(false);
    const toggleMenu = (value:boolean) => {
        setIsMenuOpen_(!isMenuOpen_);
        // const wallet: wallet = {
        //     isConnectModelOpen:true,
        //     isWalletConnect:!isMenuOpen_
        // }
        // setWalletContext(wallet);
      };
    

    const wallet = () => {
    navigate('/Wallet');
    } 
    const analyser = () => {
        navigate('/analyse');

        //   function tokenInfo(address: any, imageUrl: any) {
    // console.log('imgUrl', imageUrl)
    const obj = {
      
        name: "bsc" ,
      image:"/static/media/eth.d4d819fc09df8f7f5a99642149ee9b6c.svg"}
    setTokenContext(obj);
//   }
    } 

    const totalBalance = Number(useAccountBalance?.data?.formatted); 

    function  copyToClipboard(text: any) {
        navigator.clipboard.writeText(text).then(() => {
        //   setCopied(true);
        //   setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
        });
        toast.success(`Successfully Copied ${text}`)
    };


    const explore:any = `${networkName_1?.blockExplorers?.default?.url}/address/${useAccount_?.address}`;
    return (


        <nav className="flex md:block">
            <div className="absolute top-0 w-full mr-auto md:mx-auto px-[20px] lg:px-[30px]">
                <div className="relative flex pt-[15px] items-center justify-between">
                    <div className='flex items-center'>
                        <div className="flex flex-shrink-0 items-center">
                            <a href='/swap'>     <img className="h-10 xl:h-12 w-auto" src={logo} alt="Your Company" /></a>
                        </div>
                        <div className="hidden lg:ml-[12px] sm:block menu-hide">
                            <div className="flex items-center">
                                <div>
                                <a onClick={analyser}  className='lg:text-[16px] text-[13px] text-[#9b9b9b] font-medium py-[8px] px-[10px] lg:px-[14px] hover:bg-[#222226fa] border border-transparent hover:border-[#34373F] rounded-xl cursor-pointer'>Analyse</a>
                                    {/* <a href='/swap' className='text-[#9b9b9b] font-medium py-[8px] px-[14px] hover:bg-[#1c1d21] rounded-xl cursor-pointer'>Swap</a> */}
                                    <a href="/swap"><button  id="dropdownDefaultButtonN" data-dropdown-toggle="dropdownN" className="leading-[20px] lg:text-[16px] text-[13px] flex justify-between  text-[#9b9b9b] leading-[20px] bg-transparent  focus:none focus:outline-none focus:ring-blue-300 font-medium rounded-xl text-md px-[10px] lg:px-[14px] py-[8px] text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 hover:bg-[#222226fa] border border-transparent hover:border-[#34373F]" type="button">Swap
                                        {/* <svg className="w-2.5 h-2.5 ms-2 lg:ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
                                        </svg> */}
                                    </button>
                                    </a>

                                    {/* <div id="dropdownN" className="border border-[#34373F] z-10 hidden bg-[#222226fa] divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"> */}
                                        {/* <ul className="text-left py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButtonN">
                                        <li className='hover:bg-[#424242]'>
                                                <a href="/swap" className="text-[16px] text-[#E7E8DE] block px-4 py-2">Best Swap</a>
                                            </li>
                                            <li className='hover:bg-[#424242]'>
                                                <a href="/advancedswap" className="text-[16px] text-[#E7E8DE] block px-4 py-2 ">Advanced Swap</a>
                                            </li>
                                           


                                        </ul> */}
                                    {/* </div> */}
                                    <button id="dropdownDefaultButtonC" data-dropdown-toggle="dropdownC" className="leading-[20px] lg:text-[16px] text-[13px] flex justify-between  text-[#9b9b9b] leading-[20px] bg-transparent  focus:none focus:outline-none focus:ring-blue-300 font-medium rounded-xl text-md px-[10px] lg:px-[14px] py-[8px] text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 hover:bg-[#222226fa] border border-transparent hover:border-[#34373F] " type="button">Compare
                                        <svg className="w-2.5 h-2.5 ms-2 lg:ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
                                        </svg>
                                    </button>


                                    <div id="dropdownC" className="border border-[#34373F] z-10 hidden bg-[#222226fa] divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                                        <ul className="text-left py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButtonC">
                                            <li className='hover:bg-[#424242]'>
                                                <a href="/proswap" className="text-[16px] text-[#E7E8DE] block px-4 py-2 ">On-chain</a>
                                            </li>
                                            <li className='hover:bg-[#424242]'>
                                                <a href="/proswap" className="text-[16px] text-[#E7E8DE] block px-4 py-2 ">Cross-chain</a>
                                            </li>
                                        </ul>
                                    </div>



                                    <button id="dropdownDefaultButtonB" data-dropdown-toggle="dropdownB" className="leading-[20px] lg:text-[16px] text-[13px] flex justify-between  text-[#9b9b9b] leading-[20px] bg-transparent  focus:none focus:outline-none focus:ring-blue-300 font-medium rounded-xl text-md px-[10px] lg:px-[14px] py-[8px] text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 hover:bg-[#222226fa] border border-transparent hover:border-[#34373F]" type="button">Bridges
                                        <svg className="w-2.5 h-2.5 ms-2 lg:ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
                                        </svg>
                                    </button>


                                    <div id="dropdownB" style={{width:229}} className="border border-[#34373F] z-[999] hidden bg-[#222226fa] divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                                        <ul className="text-left py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButtonB">
                                            <li className=' hover:bg-[#424242]'>
                                                <a  href="https://cbridge.celer.network/250/1/GEL" target="_blank"  className="px-[16px] py-[10px] flex gap-3 text-white text-[16px] font-normal"><img className="h-5.5 w-5 rounded-full " src={btc} alt="Your Company" />BNB Chain bridge
                                                </a>
                                            </li>
                                            {/* <li className=' hover:bg-[#424242]'>
                                                <a href="https://app.1inch.io/#/1/simple/swap/ETH" target="_blank"  className=" px-[16px] py-[10px] flex gap-3 text-white text-[16px] font-normal"><img className="h-5.5 w-5 rounded-full " src={Ethereum} alt="Your Company" />Ethereum bridge</a>
                                            </li> */}
                                            <li className=' hover:bg-[#424242]'>
                                                <a href="https://wallet.polygon.technology/?redirectOnConnect=%2Fpolygon%2Fbridge%2F" target="_blank"  className="px-[16px] py-[10px] flex gap-3 text-white text-[16px] font-normal"><img className="h-5.5 w-5 rounded-full " src={Polygon} alt="Your Company" />Polygon bridge</a>
                                            </li>
                                            <li className=' hover:bg-[#424242]'>
                                                <a href="https://core.app/bridge/" target="_blank"  className="px-[16px] py-[10px] flex gap-3 text-white text-[16px] font-normal"><img className="h-5.5 w-5 rounded-full " src={avalanche1} alt="Your Company" />Avalanche bridge</a>
                                            </li>
                                            {/* <li className=' hover:bg-[#424242]'>
                                                <a href="https://app.1inch.io/#/1/simple/swap/ETH" target="_blank"  className="px-[16px] py-[10px] flex gap-3 text-white text-[16px] font-normal">
                                                    <svg width="20" height="20" aria-labelledby="titleID" ><title id="titleID"></title><rect rx="6" fill="#1B1B1B" width="20" height="20"></rect><rect rx="6" fill="#FCFF5233" width="20" height="20"></rect><svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.9844 5.95312H6.01562V17.9219H17.9844V13.7438H15.9969C15.3125 15.2688 13.7719 16.3281 12.0094 16.3281C9.57812 16.3281 7.60938 14.3406 7.60938 11.9281C7.60625 9.51563 9.57812 7.54688 12.0094 7.54688C13.8063 7.54688 15.3469 8.64062 16.0312 10.2H17.9844V5.95312Z" fill="#FCFF52"></path></svg></svg>
                                                    Celo
                                                </a>
                                            </li> */}
                                            <li className=' hover:bg-[#424242]'>
                                                <a href="https://bridge.base.org/deposit" target="_blank"  className="px-[16px] py-[10px] flex gap-3 text-white text-[16px] font-normal">
                                                    <img className="h-5.5 w-5 rounded-full " src={base_1} alt="Your Company" />
                                                    
                                                    Base bridge</a>
                                            </li>
                                            <li className=' hover:bg-[#424242]'>
                                                <a href="https://cbridge.celer.network/250/1/WOO" target="_blank"  className="px-[16px] py-[10px] flex gap-3 text-white text-[16px] font-normal"><img className="h-5.5 w-5 rounded-full " src={Fantom} alt="Your Company" />Fantom bridge</a>
                                            </li>
                                            <li className=' hover:bg-[#424242]'>
                                                <a href="https://app.optimism.io/bridge/deposit" target="_blank"  className="px-[16px] py-[10px] flex gap-3 text-white text-[16px] font-normal"><img className="h-5.5 w-5 rounded-full " src={Optimism} alt="Your Company" />Optmisim bridge</a>
                                            </li>
                                            <li className=' hover:bg-[#424242]'>
                                                <a href="https://core.app/bridge/" target="_blank"  className="px-[16px] py-[10px] flex gap-3 text-white text-[16px] font-normal"><img className="h-5.5 w-5 rounded-full " src={aurora1} alt="Your Company" />Aurora bridge</a>
                                            </li>
                                            <li className=' hover:bg-[#424242]'>
                                                <a href="https://bridge.arbitrum.io/?destinationChain=arbitrum-one&sourceChain=ethereum" target="_blank"  className="px-[16px] py-[10px] flex gap-3 text-white text-[16px] font-normal"><img className="h-5.5 w-5 rounded-full " src={arbitrium} alt="Your Company" />Arbitrium bridge</a>
                                            </li>
                                            <li className=' hover:bg-[#424242]'>
                                                <a href="https://portal.zksync.io/bridge/" target="_blank"  className="px-[16px] py-[10px] flex gap-3 text-white text-[16px] font-normal"><img className="h-5.5 w-5 rounded-full " src={zkSync1} alt="Your Company" />ZKSync Era bridge</a>
                                            </li>
                                            <li className=' hover:bg-[#424242]'>
                                                <a href="https://omni.legacy.gnosischain.com/bridge" target="_blank"  className="px-[16px] py-[10px] flex gap-3 text-white text-[16px] font-normal"><img className="h-5.5 w-5 rounded-full " src={Gnosis} alt="Your Company" />Genosis Chain bridge</a>
                                            </li>
                                            {/* <li className='px-[16px] py-[10px] hover:bg-[#424242]'>
                                                <a href="https://core.app/bridge/" target="_blank"  className="flex gap-3 text-white text-[16px] font-normal"><img className="h-5.5 w-5 rounded-md " src={avalanche1} alt="Your Company" />Avalanche</a>
                                            </li>
                                            <li className='px-[16px] py-[10px] hover:bg-[#424242]'>
                                                <a href="https://core.app/bridge/" target="_blank"  className="flex gap-3 text-white text-[16px] font-normal"><img className="h-5.5 w-5 rounded-md " src={avalanche1} alt="Your Company" />Avalanche</a>
                                            </li>
                                            <li className='px-[16px] py-[10px] hover:bg-[#424242]'>
                                                <a href="https://core.app/bridge/" target="_blank"  className="flex gap-3 text-white text-[16px] font-normal"><img className="h-5.5 w-5 rounded-md " src={avalanche1} alt="Your Company" />Avalanche</a>
                                            </li>
                                            <li className='px-[16px] py-[10px] hover:bg-[#424242]'>
                                                <a href="https://core.app/bridge/" target="_blank"  className="flex gap-3 text-white text-[16px] font-normal"><img className="h-5.5 w-5 rounded-md " src={avalanche1} alt="Your Company" />Avalanche</a>
                                            </li>
                                            <li className='px-[16px] py-[10px] hover:bg-[#424242]'>
                                                <a href="https://core.app/bridge/" target="_blank"  className="flex gap-3 text-white text-[16px] font-normal"><img className="h-5.5 w-5 rounded-md " src={avalanche1} alt="Your Company" />Avalanche</a>
                                            </li> */}
                                        </ul>
                                    </div>
                                    {/* <button id="dropdownDefaultButtonT" data-dropdown-toggle="dropdownT" className="leading-[20px] lg:text-[16px] text-[13px] flex justify-between  text-[#9b9b9b] leading-[20px] bg-transparent  focus:none focus:outline-none focus:ring-blue-300 font-medium rounded-xl text-md px-[10px] lg:px-[14px] py-[8px] text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 hover:bg-[#222226fa] border border-transparent hover:border-[#34373F]" type="button">Treasures
                                        <svg className="w-2.5 h-2.5 ms-2 lg:ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
                                        </svg>
                                    </button> */}


                                    <div id="dropdownT" className="w-[230px] border border-[#34373F] z-10 hidden bg-[#222226fa] divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                                        <ul className="text-left py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButtonT">
                                            <li className='px-[16px] py-[10px] hover:bg-[#424242]'>
                                                <a   className="flex gap-3 text-white text-[16px] font-normal"><img className="h-5.5 w-5 rounded-full border border-[#34373f] " src={dai1} alt="Your Company" />linear Treasures
                                                </a>
                                            </li>
                                            <li className='px-[16px] py-[10px] hover:bg-[#424242]'>
                                                <a   className="flex gap-3 text-white text-[16px] font-normal whitespace-nowrap"><img className="h-5.5 w-5 rounded-full border border-[#34373f] " src={trinew} alt="Your Company" />triangular Treasures
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* <a href='/compare' className='text-[#9b9b9b] font-medium py-[8px] px-[14px] hover:bg-[#1c1d21] rounded-xl cursor-pointer'>Compare</a> */}
                                    <a href='/buy' className='lg:text-[16px] text-[13px] text-[#9b9b9b] font-medium py-[8px] px-[10px] lg:px-[14px] hover:bg-[#222226fa] border border-transparent hover:border-[#34373F] rounded-xl cursor-pointer'>Buy Crypto</a>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='flex items-center gap-[5px] lg:gap-[10px]'>
                        {/* <Dropdown1 /> */}

                        {useAccount_?.status === 'connected' ?
                            (
                               
                                <div className='flex items-center pt-[3px]' > 
                                <div className="relative ">
                                        <button
                                        onClick={openChainModal}
                                        id="dropdownDefaultButtonhead"
                                        data-dropdown-toggle="dropdownhead"
                                        className="h_drop right-0 top-1 inline-flex items-center rounded-full
                                        bg-[#202027] bg-transparent hover:bg-[#292929]
                                        text-white rounded-xl px-2.5 py-2.5 text-md font-medium flex gap-1 focus:outline-none focus-visible:outline-none"
                                        type="button"
                                    >
                                        <p className=""><img className="h-5.5 w-5 rounded-full " src={selectedChain.iconUrl} alt="Your Company" /></p>
                                        <svg className='h-5 w-6' xmlns="http://www.w3.org/2000/svg" version="1.1" width="512" height="512" x="0" y="0" viewBox="0 0 24 24"><g><path d="M18.7 7.2c-.4-.4-1-.4-1.4 0l-7.5 7.5-3.1-3.1c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3.8 3.8c.2.2.4.3.7.3s.5-.1.7-.3l8.2-8.2c.4-.4.4-1 0-1.4z" fill="#919192" opacity="1" data-original="#000000"></path></g></svg>
                                    </button>

                                    <div
                                        id="dropdownhead"
                                        className="z-10 bg-[#202027] divide-y divide-gray-100 rounded-b-xl shadow w-44 dark:bg-gray-700 w-[64px] top-[-22px] -translate-y-6 block"
                                        data-popper-placement="bottom"
                                    >

                                    </div>
                                </div>

                                <div onClick={() =>toggleMenu(true)} className="cursor-pointer text-white text-[8px] sm:text-[16px] py-[10px] px-[12px]   rounded-full inline text-center font-normal  flex items-center gap-[8px] hover:bg-[#292929] "><img className='h-[20px] w-[20px] md:h-[24px] md:w-[24px] rounded-full' src={tc} />
                                        <span className="font-[Lausanne] text-[12px] md:text-[15px] font-bold text-gradient ">{truncatedAddress}</span>
                                </div>
                                </div>
                            ) : (
                                <div className='ml-[0px] lg:ml-[12px]'>
                                {/* <ConnectButton showBalance={{ smallScreen: true, largeScreen: true }}
                                    accountStatus={{ smallScreen: 'avatar', largeScreen: 'full', }} /> */}
                                <button onClick={openWalletModel} className='bg-[#424242] py-[10px] px-[8px] lg:px-[12px] rounded-full leading-0 text-[#b1b2ff] text-[14px] lg:text-[16px] font-medium '><span className='text-gradient'>Connect Wallet</span></button>

                                </div>
                            )
                        }
                        
                        <div id="mobile-menu" className={isMenuOpen_ ? 'pl-[16px] md:hidden mr-[20px] pt-[15px] block space-y-1 px-4 pb-10 pt-2 fixed top-0 left-0 z-30 w-full h-full bg-[#181819] z-[999999]' : 'md:hidden mr-[0px] mt-[0px]'}>
                            <div className="lg:flex inset-y-0 left-0 justify-end flex items-center sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                <a href="#" className="bg-[#22232ac7] text-white rounded-xl px-2.5 py-2.5 text-md font-medium flex gap-2" aria-current="page" >
                                    {isMenuOpen_ ? (
                                        <svg className='h-4 w-4' xmlns="http://www.w3.org/2000/svg" version="1.1" width="24" height="24" viewBox="0 0 24 24">

                                            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" fill="#ffffff" />
                                        </svg>
                                    ) : (
                                        <svg className='h-4 w-4' xmlns="http://www.w3.org/2000/svg" version="1.1" width="512" height="512" x="0" y="0" viewBox="0 0 464.205 464.205">

                                            <path d="M435.192 406.18H29.013C12.989 406.18 0 393.19 0 377.167s12.989-29.013 29.013-29.013h406.18c16.023 0 29.013 12.99 29.013 29.013-.001 16.023-12.99 29.013-29.014 29.013zM435.192 261.115H29.013C12.989 261.115 0 248.126 0 232.103s12.989-29.013 29.013-29.013h406.18c16.023 0 29.013 12.989 29.013 29.013s-12.99 29.012-29.014 29.012zM435.192 116.051H29.013C12.989 116.051 0 103.062 0 87.038s12.989-29.013 29.013-29.013h406.18c16.023 0 29.013 12.989 29.013 29.013s-12.99 29.013-29.014 29.013z" fill="#ffffff" opacity="1" data-original="#000000"></path>
                                        </svg>
                                    )}
                                </a>
                            </div>
                            
                        </div>
                    </div>
                </div>


            </div>
            {isMenuOpen_ &&  (
                <div style={{top:"4.5rem"}} className='wallet absolute right-0  flex flex-col justify-end items-end max-w-[1678px]'>
               <div className=" justify-end items-end relative ">
                        <div style={{ marginRight: 25 }} className='wallet border relative z-10  mr-27   rounded-2xl border-[#34373F] bg-[#2222268a] rounded-2xl text-[#E7E8DE] p-[10px] min-h-[300px] w-[370px] md:w-[400px]'>
                       <div className=''>
                           <div className=" mb-[15px]">
                               <div className="flex justify-between items-center -mb-px text-sm font-medium text-center bg-[#424242] py-2 px-2 rounded-2xl">
                                   <div className="me-2 " role="presentation">
                                            <button className="inline-block  font-[500] text-[#E7E8DE] border border-0 rounded-2xl text-[14px] md:text-[16px] " id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">
                                                <div className="text-white text-[8px] sm:text-[16px] py-[10px] px-[15px] bg-transparent inline text-center font-normal  flex items-center gap-[8px]">
                                            <img className='h-[20px] w-[20px] md:h-[24px] md:w-[24px] rounded-full' src={tc} />
                                            <span className="font-[Inter] text-[12px] md:text-[13px] font-bold text-gradient ">{ truncatedAddress}</span></div></button>
                                   </div>
                                 <div className='flex items-center'>
                                <img className='h-[20px] w-[20px] md:h-[24px] md:w-[24px] rounded-full' src={useAccount_.status === 'disconnected' ? bnc:networkName_1.iconUrl}  />
                               <p className='px-[10px] md:px-[15px] py-[5px] md:py-[10px] '>
                               <svg onClick={() => { disconnect(); toggleMenu(true) }} className="h-5 w-5 text-[#E7E8DE] cursor-pointer" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">  <line x1="1" y1="1" x2="23" y2="23" />  <path d="M16.72 11.06A10.94 10.94 0 0 1 19 12.55" />  <path d="M5 12.55a10.94 10.94 0 0 1 5.17-2.39" />  <path d="M10.71 5.05A16 16 0 0 1 22.58 9" />  <path d="M1.42 9a15.91 15.91 0 0 1 4.7-2.88" />  <path d="M8.53 16.11a6 6 0 0 1 6.95 0" />  <line x1="12" y1="20" x2="12.01" y2="20" /></svg>
                               </p>
                                 </div>
                               </div>
   
   
                           </div>
                           <div >
                              
                               
                               <div className=" " id="settings" role="tabpanel" aria-labelledby="settings-tab">
                                   <div className='rounded-2xl justify-start border-[1px] border-[#34373F] py-[10px] px-[15px]'>
                                       <p className='text-[18px] font-[400] flex'>My balance</p>
                                       <div className='flex justify-between gap-[50px] items-center mt-[10px]'>
                                                   <p className='text-[34px]'>{String(useAccountBalance?.data?.value  ? totalBalance.toFixed(7) : '0')}</p>
                                                   <div className='flex items-center gap-2 bg-[#424242] rounded-xl px-4 py-2'><span>{useAccountBalance?.data?.symbol}</span></div>
                                       </div>
                                   </div>
                                   <div className='bg-[#424242] rounded-2xl mt-[15px] p-[10px] px-[15px]'>
                                       <div className='flex items-center justify-between border-b border-[#34373F] '>
                                           <a onClick={() => copyToClipboard(useAccount_?.address)} className='text-white font-bold py-2 px-4 rounded  cursor-pointer w-[50%] text-center text-[16px] font-[500] flex items-center gap-3 justify-start py-3 px-2 border-r border-[#34373F]'><svg className="h-5 w-5 text-[#E7E8DE]" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">  <rect x="9" y="9" width="13" height="13" rx="2" ry="2" />  <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" /></svg>Copy</a>
                                           <a href={explore} target="_blank"  className='text-white font-bold py-2 px-4 rounded   w-[50%] cursor-pointer text-center text-[16px] font-[500] flex items-center gap-3 justify-start py-3 px-5'><svg className="h-5 w-5 text-[#E7E8DE]" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <circle cx="10" cy="10" r="7" />  <line x1="21" y1="21" x2="15" y2="15" /></svg>Explorer</a>
                                       </div>
                                       <div className='flex cursor-pointer items-center justify-between'>
                                           <a href='/buy' className='text-white font-bold py-2 px-4 rounded   w-[50%] text-center text-[16px] font-[500] flex items-center gap-3 justify-start py-3 px-2 border-r border-[#34373F]'><svg className="h-5 w-5 text-[#E7E8DE]" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <path d="M6 6h8a3 3 0 0 1 0 6a3 3 0 0 1 0 6h-8" />  <line x1="8" y1="6" x2="8" y2="18" />  <line x1="8" y1="12" x2="14" y2="12" />  <line x1="9" y1="3" x2="9" y2="6" />  <line x1="13" y1="3" x2="13" y2="6" />  <line x1="9" y1="18" x2="9" y2="21" />  <line x1="13" y1="18" x2="13" y2="21" /></svg>Buy Crypto</a>
                                                   {useAccount_.status === 'disconnected' ?
                                                       (<a onClick={openWalletModel} className='text-white font-bold py-2 px-4 rounded   w-[50%] text-center text-[16px] font-[500] flex items-center gap-3 justify-start py-3 px-5'><svg className="h-5 w-5 text-[#E7E8DE]" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">  <line x1="1" y1="1" x2="23" y2="23" />  <path d="M16.72 11.06A10.94 10.94 0 0 1 19 12.55" />  <path d="M5 12.55a10.94 10.94 0 0 1 5.17-2.39" />  <path d="M10.71 5.05A16 16 0 0 1 22.58 9" />  <path d="M1.42 9a15.91 15.91 0 0 1 4.7-2.88" />  <path d="M8.53 16.11a6 6 0 0 1 6.95 0" />  <line x1="12" y1="20" x2="12.01" y2="20" /></svg>Connect</a>
                                                       
                                                       ):(
                                                        <a onClick={() => { disconnect(); toggleMenu(true) }} className='text-white font-bold py-2 px-4 rounded   w-[50%] cursor-pointer text-center text-[16px] font-[500] flex items-center gap-3 justify-start py-3 px-5'><svg className="h-5 w-5 text-[#E7E8DE]" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">  <line x1="1" y1="1" x2="23" y2="23" />  <path d="M16.72 11.06A10.94 10.94 0 0 1 19 12.55" />  <path d="M5 12.55a10.94 10.94 0 0 1 5.17-2.39" />  <path d="M10.71 5.05A16 16 0 0 1 22.58 9" />  <path d="M1.42 9a15.91 15.91 0 0 1 4.7-2.88" />  <path d="M8.53 16.11a6 6 0 0 1 6.95 0" />  <line x1="12" y1="20" x2="12.01" y2="20" /></svg>Disconnect</a>
                                                       )}
                                               </div>
                                   </div>
                               </div>
                              
                           </div>
   
                       </div>
   
                   </div> 
                </div>
               </div>
            )}
            {openWallet && (
                <div className='flex md:flex-row flex-col gap-4 justify-center'>
                           
                    <div className='m-auto w-11/12 md:w-1/2 lg:w-4/12 xxl:w-3/12 mt-[100px]  rounded-2xl bg-[#2222268a] border border-[#34373F]'>
                        <div className='relative items-center justify-between gap-2 py-[20px] px-[20px]'>
                            <div className='flex justify-end'>
                                <svg className="h-6 w-6 text-[#c59eb8] cursor-pointer" onClick={() =>closeWalletModel()} viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <line x1="15" y1="9" x2="9" y2="15" />  <line x1="9" y1="9" x2="15" y2="15" /></svg>
                            </div>
                            <div className='text-center'>     <p className='text-[#d3d3d3] text-[#E7E8DE] text-[20px] font-semibold'>Connect wallet</p>
                                <p className='text-[#8e8e91] text-[12px] pt-[5px]'>Select a wallet you want to conect to Objects.</p>  </div>
                                 
                        </div>
                             
                        <div className='py-[20px] px-[20px] pt-0'>

                             
                            <div className='mic_list h-[350px] overflow-hidden overflow-y-auto scroll-h mt-[30px]'>
                                {/* <div className='flex items-center justify-between p-[10px] px-[20px] mb-[10px] rounded-lg border border-transparent hover_gradient cursor-pointer'>
                                    <div className='flex justify-start gap-[15px] items-center '>
                                        <div> <img className='w-[35px] h-[35px] border border-[#c59eb8] rounded-full' src={con1} /> </div>
                                        <div className='text-left'>
                                            <p className='text-[15px] md:text-[14px] md:text-[16px] leading-[20px] font-[400] text-white text-left flex gap-2 items-center capitalize'>Argent</p>
                                        </div>
                                    </div>
                                    <div className='flex items-center'>
                                        <div className="flex items-center ">
                                            {useAccount_.status === 'connected' ? (
                                                <WalletButton wallet="metamask" />
                                            ) : (
                                                <WalletButton.Custom wallet="metamask">
                                                    {({ ready, connect }) => {
                                                        return (
                                                            <button
                                                                className='text-[#8e8e91] text-gradient opacity-0 wallet-text font-medium text-[14px]'
                                                                type="button"
                                                                disabled={!ready}
                                                                onClick={connect}
                                                            >
                                                                Connect
                                                            </button>
                                                        );
                                                    }}
                                                </WalletButton.Custom>
                                            )}
                                        </div>
                                    </div>
                                </div> */}
                                <div className='flex items-center justify-between p-[10px] px-[20px] mb-[10px] rounded-lg border border-transparent hover_gradient cursor-pointer'>
                                    <div className='flex justify-start gap-[15px] items-center '>
                                        <div> <img className='w-[35px] h-[35px] border border-[#c59eb8] rounded-full' src={con2} /> </div>
                                        <div className='text-left'>
                                            <p className='text-[15px] md:text-[14px] md:text-[16px] leading-[20px] font-[400] text-white text-left flex gap-2 items-center capitalize'>Coinbase wallet</p>
                                        </div>
                                    </div>
                                    <div className='flex items-center'>
                                        <div className="flex items-center ">
                                        {useAccount_.status === 'connected' ? (
                                                <WalletButton wallet="coinbase" />
                                            ) : (
                                                <WalletButton.Custom wallet="coinbase">
                                                    {({ ready, connect }) => {
                                                        return (
                                                            <button
                                                                className='text-[#8e8e91] text-gradient opacity-0 wallet-text font-medium text-[14px]'
                                                                type="button"
                                                                // disabled={!ready}
                                                                onClick={connect}
                                                            >
                                                                Connect
                                                            </button>
                                                        );
                                                    }}
                                                </WalletButton.Custom>
                                            )}
                                            {/* <p className='text-[#8e8e91] text-gradient opacity-0 wallet-text font-medium text-[14px]'>Connect</p> */}
                                        </div>
                                    </div>
                                </div>
                                <div className='flex items-center justify-between p-[10px] px-[20px] mb-[10px] rounded-lg border border-transparent hover_gradient cursor-pointer'>
                                    <div className='flex justify-start gap-[15px] items-center '>
                                        <div> <img className='w-[35px] h-[35px] border border-[#c59eb8] rounded-full' src={con3} /> </div>
                                        <div className='text-left'>
                                            <p className='text-[15px] md:text-[14px] md:text-[16px] leading-[20px] font-[400] text-white text-left flex gap-2 items-center capitalize'>Metamask</p>
                                        </div>
                                    </div>
                                    <div className='flex items-center'>
                                        <div className="flex items-center ">
                                        {useAccount_.status === 'connected' ? (
                                                <WalletButton wallet="metamask" />
                                            ) : (
                                                <WalletButton.Custom wallet="metamask">
                                                    {({ ready, connect }) => {
                                                        return (
                                                            <button
                                                                className='text-[#8e8e91] text-gradient opacity-0 wallet-text font-medium text-[14px]'
                                                                type="button"
                                                                // disabled={!ready}
                                                                onClick={connect}
                                                            >
                                                                Connect
                                                            </button>
                                                        );
                                                    }}
                                                </WalletButton.Custom>
                                            )}
                                            {/* <p className='text-[#8e8e91] text-gradient opacity-0 wallet-text font-medium text-[14px]'>Connect</p> */}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='flex items-center justify-between p-[10px] px-[20px] mb-[10px] rounded-lg border border-transparent hover_gradient cursor-pointer'>
                                    <div className='flex justify-start gap-[15px] items-center '>
                                        <div> <img className='w-[35px] h-[35px] border border-[#c59eb8] rounded-full' src={con4} /> </div>
                                        <div className='text-left'>
                                            <p className='text-[15px] md:text-[14px] md:text-[16px] leading-[20px] font-[400] text-white text-left flex gap-2 items-center capitalize'>Wallet connect</p>
                                        </div>
                                    </div>
                                    <div className='flex items-center'>
                                        <div className="flex items-center ">
                                        {useAccount_.status === 'connected' ? (
                                                <WalletButton wallet="metamask" />
                                            ) : (
                                                <WalletButton.Custom wallet="metamask">
                                                    {({ ready, connect }) => {
                                                        return (
                                                            <button
                                                                className='text-[#8e8e91] text-gradient opacity-0 wallet-text font-medium text-[14px]'
                                                                type="button"
                                                                disabled={!ready}
                                                                onClick={connect}
                                                            >
                                                                Connect
                                                            </button>
                                                        );
                                                    }}
                                                </WalletButton.Custom>
                                            )}
                                        </div>
                                    </div>
                                </div> */}
                                <div className='flex items-center justify-between p-[10px] px-[20px] mb-[10px] rounded-lg border border-transparent hover_gradient cursor-pointer'>
                                    <div className='flex justify-start gap-[15px] items-center '>
                                        <div> <img className='w-[35px] h-[35px] border border-[#c59eb8] rounded-full' src={rainbow} /> </div>
                                        <div className='text-left'>
                                            <p className='text-[15px] md:text-[14px] md:text-[16px] leading-[20px] font-[400] text-white text-left flex gap-2 items-center capitalize'> Rainbow Wallet</p>
                                        </div>
                                    </div>
                                    <div className='flex items-center'>
                                        <div className="flex items-center ">
                                        {useAccount_.status === 'connected' ? (
                                                <WalletButton wallet="rainbow" />
                                            ) : (
                                                <WalletButton.Custom wallet="rainbow">
                                                    {({ ready, connect }) => {
                                                        return (
                                                            <button
                                                                className='text-[#8e8e91] text-gradient opacity-0 wallet-text font-medium text-[14px]'
                                                                type="button"
                                                                // disabled={!ready}
                                                                onClick={connect}
                                                            >
                                                                Connect
                                                            </button>
                                                        );
                                                    }}
                                                </WalletButton.Custom>
                                            )}
                                            {/* <p className='text-[#8e8e91] text-gradient opacity-0 wallet-text font-medium text-[14px]'>Connect</p> */}
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className='text-center text-white text-[14px] pt-[20px]'><p className='font-normal'> By connecting your wallet, you agree to our &nbsp;<br /><a href='/termsofuse' target='_blank' className='text-gradient font-medium'> Terms of service </a>and our
                                <a href='/privacypolicy' target='_blank' className='text-gradient font-medium'> &nbsp;Privacy Policy</a></p></div>
                        </div>
                    </div>
                    {/* <div className='m-auto w-11/12 md:w-1/2 lg:w-4/12 xxl:w-3/12 mt-[15px]  rounded-2xl bg-[#2222268a] border border-[#34373F]'>
                        <div className='relative items-center justify-between gap-2 py-[20px] px-[20px]'>
                            <div className='flex justify-between items-center'>
                                <svg className="h-6 w-6 text-white" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <line x1="5" y1="12" x2="19" y2="12" />  <line x1="5" y1="12" x2="11" y2="18" />  <line x1="5" y1="12" x2="11" y2="6" /></svg>

                                <svg className="h-6 w-6 text-[#c59eb8]" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <line x1="15" y1="9" x2="9" y2="15" />  <line x1="9" y1="9" x2="15" y2="15" /></svg>
                            </div>
                            <div className='text-center'>     <p className='text-[#d3d3d3] text-[#E7E8DE] text-[20px] font-semibold'>Scan with Coinbase Wallet</p>
                                 
                            </div>
                                   
                        </div>
                             
                        <div className='py-[20px] px-[20px] pt-0'>

                            <div className=''>
                                <img className='h-full w-full rounded-xl' src={scan} />
                            </div>
                              
                        </div>
                    </div> */}
                </div>
            )}
            

        </nav>

    );
};

export default Headerfull;



