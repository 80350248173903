
import Headerfull from '../components/headerfull';
import eth from "../images/eth.svg";
import prog1 from "../images/prog1.svg";
import prog2 from "../images/prog2.svg";
import prog3 from "../images/prog3.svg";
import pp from "../images/pp.svg";
import pp1 from "../images/pp1.svg";
import thund from "../images/thun.png";
import graph from "../images/grapgh.svg";
import uniswap from "../images/uni.svg";
import susi from "../images/susi.svg";
import f4 from "../images/Design_Colored 16x16.svg";
import Footerlinkstrans from '../components/footerlinkstransparent';
import { useTokenContext } from '../Provider/useContext';
// import MyProvider  from '../Provider/useContext';
// import { useContext } from 'react';
// import { Analyse2 ,useTokenContext} from '../pages/analyse2'
// import { useTokenContext } from './analyse2';
import { Children, useEffect, useState } from 'react';
import { useToken } from '@lifi/widget/hooks';

export const Credit4 = () => {
    // const [myProvider,useTokenContext_] = useState(useTokenContext) as any;
    // const { provider } = useContext(MyContext) as any;
    // const {tokenContext} =
    // console.log("ProvidetokenContext",tokenContext)
    // console.log("🚀 ~ Credit4 ~ obj:", provider)
    // const analyse2 = useTokenContext( );
    // useEffect(() => {
        //     if (analyse2) {
            
            // console.log("🚀 ~ Credit4 ~ analyse2:", analyse2)
    // }
    // },[analyse2])
    // const [tokenInfo, setTokenInfo] = useTokenContext()as any;
    // Assuming pairInfo is expected to have a data property
interface PairInfo {
    data: {
      creationBlock: number;
      metrics: {
        reserve: number;
        reserveRef: number;
        liquidity: number;
        // Add other properties as needed
      };
      // Add other properties as needed
    };
  }
  
  
  // Type assertion
  
  const result = useTokenContext();
  const liquidity: PairInfo | any = result?.tokenContext?.pairInfo;
  console.log("🚀 ~ Credit4 ~ liquidity:", liquidity?.data?.metrics?.liquidity as PairInfo['data']);

    console.log("🚀 ~ Credit4 ~ result:", result)
    // const liquidity = result?.tokenContext;
    // const pairInfo = liquidity?.pairInfo;
    // console.log("🚀 ~ Credit4 ~ liquidity:", pairInfo?.data)
    
    function formattedNumber (num: any) {
        const numStr = num.toString();
        const reversedStr = numStr.split('').reverse().join('');
        const resultStr = reversedStr
          .match(/.{1,3}/g)
          ?.join(',')
          .split('')
          .reverse()
          .join('');
    
        return resultStr || '';
      };
    
    function  copyToClipboard(text: any) {
        navigator.clipboard.writeText(text).then(() => {
        //   setCopied(true);
        //   setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
        });
    };
    
    return (
        <>
            {/* <useTokenContext>
                {Children}
            </useTokenContext> */}

            <div className='main'>

                        <Headerfull name="John" />
                <div className="App">
                </div>

                <div className='pt-[150px]'>
                    <div className='mx-auto px-[20px] sm:px-20px] lg:px-[30px] md:max-w-[921px] 2xl:max-w-[1228px] text-center '>
                        <div className='inline-flex bg-[#292929] text-[#E7E8DE] text-[12px] sm:text-[16px] px-3 md:px-4 py-2 pb-2.5 rounded-full inline text-center font-normal pt-[9px]'>Oh hey 👋🏽 <span className='pl-1 pt-0.5 flex gap-2 items-center font-[Inter] text-[11px] md:text-[15px] font-bold text-gradient '>{result?.tokenContext?.tokenInfo?.data?.address}
                            <svg onClick={() => copyToClipboard(result?.tokenContext?.tokenInfo?.data?.address)} className='h-3 w-3 cursor-pointer md:h-4 md:w-4' xmlns="http://www.w3.org/2000/svg" version="1.1" width="512" height="512" x="0" y="0" viewBox="0 0 488.3 488.3" ><g><path d="M314.25 85.4h-227c-21.3 0-38.6 17.3-38.6 38.6v325.7c0 21.3 17.3 38.6 38.6 38.6h227c21.3 0 38.6-17.3 38.6-38.6V124c-.1-21.3-17.4-38.6-38.6-38.6zm11.5 364.2c0 6.4-5.2 11.6-11.6 11.6h-227c-6.4 0-11.6-5.2-11.6-11.6V124c0-6.4 5.2-11.6 11.6-11.6h227c6.4 0 11.6 5.2 11.6 11.6v325.6z" fill="#919192" opacity="1" data-original="#000000" ></path><path d="M401.05 0h-227c-21.3 0-38.6 17.3-38.6 38.6 0 7.5 6 13.5 13.5 13.5s13.5-6 13.5-13.5c0-6.4 5.2-11.6 11.6-11.6h227c6.4 0 11.6 5.2 11.6 11.6v325.7c0 6.4-5.2 11.6-11.6 11.6-7.5 0-13.5 6-13.5 13.5s6 13.5 13.5 13.5c21.3 0 38.6-17.3 38.6-38.6V38.6c0-21.3-17.3-38.6-38.6-38.6z" fill="#919192" opacity="1" data-original="#000000"></path></g></svg></span></div>
                        <div className='mt-10 sm:mt-14'>
                            <div className='font-grok text-5xl font-bold inline-block bg-gradient-to-r bg-gradient-to-r from-[#cbf2a2] to-[#cbf2a2] inline-block text-transparent bg-clip-text'>{result?.tokenContext?.credibilityInfo} <span className='text-4xl font-bold'>Credibility</span></div>
                            <div className='downloadcode mt-4 max-w-[360px] max-h-[100px] items-center mx-auto rounded-[20px] border border-[#ffffff12] bg-[#17181d54] flex'>
                                <div className='p-[15px] bg-[#9383ff] rounded-s-[20px]'><img className="h-[70px] w-[75px] " src={eth} alt="Your Company" /></div>
                                <div className='p-4 py-4.5 pr-0 pt-5 pl-[14px] text-left'>
                                    <p className='text-left text-sm font-semibold text-[#E7E8DE] pb-1.5'>Buy Crypto Safely</p>
                                    <p className='text-[11px] text-left  text-[#9b9b9b] leading-3'>Trade on the go. Anywhere, anytime. - Get it now.</p>
                                    <p className='text-left text-xs font-bold text-gradient mt-2 leading-3'>G<i>Y</i> Ads</p>
                                </div>
                            </div>
                        </div>
                    
                        <div className='daily-summary font-[Inter] block md:flex justify-between items-start mt-[100px] gap-3'>
                            <div className='text-left max-w-[455px] py-[13px] px-[14px] rounded-[19px] bg-[#2222268a] border border-[#34373F]'>
                            <nav className="inline-flex space-x-4 bg-[#272727] rounded-md p-1" aria-label="Tabs">
                                            <a href="javascript:void(0);" className=" w-full bg-[#000000] text-[#E7E8DE] rounded-md px-3 py-2 text-sm font-medium " aria-current="page">Result Summary</a>                   
                                        </nav>
                                <p className='text-[#E7E8DE] text-lg font-semibold text-left mb-2'></p>
                                <p className='text-[#E7E8DE] flex gap-2 text-left text-[16px] pt-2 pl-[40px] relative'><span className='absolute left-[-1px]'>
                                    <img src={thund} />                                </span>        Today you had 20% more meetings than
                                    usual , you closed 2 tasks on two projects, but
                                    the focus was 12% lower that yesterday.</p>
                                <div className='border-y mt-[30px] border-y-[#27282a] py-[25px] px-[3px] flex gap-0 justify-between '>
                                    <div className='text-left'>
                                        <p className='text-[13px] text-[#ffffff57]'>Total Liquidity</p>
                                        <p className='text-[28px] pt-[3px] text-[#E7E8DE] font-grok'>{ formattedNumber(Math.floor(liquidity?.data?.metrics?.liquidity))}</p>
                                    </div>
                                    <div className='text-right'>
                                        <p className='text-[13px] text-[#ffffff57]'>Trading Volume(24)</p>
                                        <p className='text-[28px] pt-[3px] text-[#E7E8DE] font-grok'>{ formattedNumber(Math.floor(result?.tokenContext?.tradingVolume))}</p>
                                    </div>
                                </div>
                                <div className='border-b mt-[30px] border-b-[#27282a] pb-[25px] px-[3px] flex gap-0 justify-between'>
                                    <div className='text-left'>
                                        <p className='text-[13px] text-[#ffffff57]'>Circulating Supply</p>
                                        <p className='text-[28px] pt-[3px] text-[#E7E8DE] font-grok'>{formattedNumber(Math.floor(result?.tokenContext?.circulatingSupply))}</p>
                                    </div>
                                    <div className='text-right'>
                                        <p className='text-[13px] text-[#ffffff57]'>Max Supply</p>
                                        <p className='text-[28px] pt-[3px] text-[#E7E8DE] font-grok'>{formattedNumber(Math.floor(result?.tokenContext?.maxSupply)) }</p>
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="w-1/3">
                                        <div className='progress_bars  pt-[25px] pr-[20px] pl-[20px] pb-[20px] '>
                                            <div className='relative'>
                                              <div className='flex items-center justify-center'>
                                              <img className='mx-auto' src={prog1} />
                                                <p className='text-[14px] text-[#E7E8DE] font-semibold absolute  left-0 right-0 flex justify-center items-center'>62%</p>
                                              </div>
                                                <p className='text-[#E7E8DE] text-center text-[14px] leading-[32px]'>Buy tax</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-1/3">
                                        <div className='progress_bars pt-[25px] pr-[20px] pl-[20px] pb-[20px] '>
                                            <div className='relative'>
                                              <div className='flex items-center justify-center'>
                                              <img className='mx-auto h-[64px]' src={prog2} />
                                                <p className='text-[14px] text-[#E7E8DE] font-semibold absolute  left-0 right-0 flex justify-center items-center'>15%</p>
                                              </div>
                                                <p className='text-[#E7E8DE] text-center text-[14px] leading-[32px]'>Sell tax</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-1/3">
                                        <div className='progress_bars  pt-[25px] pr-[20px] pl-[20px] pb-[20px] '>
                                            <div className='relative'>
                                            <div className='flex items-center justify-center'>
                                            <img className='mx-auto  h-[64px]' src={prog3} />
                                                <p className='text-[14px] text-[#E7E8DE] font-semibold absolute  left-0 right-0 flex justify-center items-center'>11%</p>
                                            </div>
                                                <p className='text-[#E7E8DE] text-[14px] leading-[32px]'>Transfer Tax </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex border-b border-b-[#27282a]">
                                    <div className="w-1/3">
                                    <div className='progress_bars    pt-[25px] pr-[20px] pl-[20px] pb-[20px] '>
                                        <div className='relative'>
                                               <div className='flex items-center justify-center'>
                                               <img className='mx-auto' src={prog1} />
                                                <p className='text-[14px] text-[#E7E8DE] font-semibold absolute  left-0 right-0 flex justify-center items-center'>62%</p>
                                               </div>
                                                <p className='text-[#E7E8DE] text-center text-[14px] leading-[32px]'>Average  tax</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-1/3 ">
                                        <div className='progress_bars pt-[25px] pr-[20px] pl-[20px] pb-[20px] '>
                                            <div className='relative'>
                                               <div className='flex items-center justify-center'>
                                               <img className='mx-auto' src={prog2} />
                                                <p className='text-[14px] text-[#E7E8DE] font-semibold absolute  left-0 right-0 flex justify-center items-center'>15%</p>
                                               </div>
                                                <p className='text-[#E7E8DE] text-[14px] leading-[32px]'>Highest Tax</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-1/3">
                                      
                                    </div>
                                </div>


                                <div className='mt-[30px]'>
                                    <p className='text-[13px] leading-[32px] font-medium text-[#ffffff57] text-left'>Simulation Results & Recent Holder Analysis</p>
                                    <div className='prog-bar flex items-center justify-between'>
                                        <p className='flex items-center text-[15px] text-[#E7E8DE] leading-[32px]'> <img className="h-[20px] w-[auto]" src={f4} alt="eth" /> &nbsp; BUY GAS</p>
                                        <div className='w-[50%] flex items-center justify-end'>
                                            
                                            <span className='text-[15px] text-[#7b61ff]'>145,734</span>
                                        </div>
                                    </div>
                                    <div className='prog-bar flex items-center justify-between'>
                                        <p className='flex items-center text-[15px] text-[#E7E8DE] leading-[32px]'><img className="h-[20px] w-[auto]" src={f4} alt="eth" /> &nbsp; SELL GAS</p>
                                        <div className='w-[50%] flex items-center justify-end'>
                                            
                                            <span className='text-[15px] text-[#7b61ff]'>145,734</span>
                                        </div>
                                    </div>
                                    
                                    <div className='prog-bar flex items-center justify-between'>
                                        <p className='flex items-center text-[15px] text-[#E7E8DE] leading-[32px]'><img className="h-[20px] w-[auto]" src={f4} alt="eth" /> &nbsp; BUY LIMIT</p>
                                        <div className='w-[50%] flex items-center justify-end'>
                                            
                                            <span className='text-[15px] text-[#7b61ff]'>$1.1K</span>
                                        </div>
                                    </div>
                                    
                                    <div className='prog-bar flex items-center justify-between'>
                                        <p className='flex items-center text-[15px] text-[#E7E8DE] leading-[32px]'><img className="h-[20px] w-[auto]" src={f4} alt="eth" /> &nbsp; SELL LIMIT</p>
                                        <div className='w-[50%] flex items-center justify-end'>
                                          
                                            <span className='text-[15px] text-[#7b61ff]'>UNKOWN</span>
                                        </div>
                                    </div>


                                    
                                    
                                    <div className='prog-bar flex items-center justify-between'>
                                        <p className='flex items-center text-[15px] text-[#E7E8DE] leading-[32px]'><img className="h-[20px] w-[auto]" src={f4} alt="eth" /> &nbsp; HOLDERS ANALYSED</p>
                                        <div className='w-[20%] flex items-center justify-end'>
                                           
                                            <span className='text-[15px] text-[#7b61ff]'>347</span>
                                        </div>
                                    </div>


                                    <div className='prog-bar flex items-center justify-between'>
                                        <p className='flex items-center text-[15px] text-[#E7E8DE] leading-[32px]'><img className="h-[20px] w-[auto]" src={f4} alt="eth" /> &nbsp; CAN SELL</p>
                                        <div className='w-[50%] flex items-center justify-end'>
                                            
                                            <span className='text-[15px] text-[#7b61ff]'>347</span>
                                        </div>
                                    </div>


                                    <div className='prog-bar flex items-center justify-between'>
                                        <p className='flex items-center text-[15px] text-[#E7E8DE] leading-[32px]'><img className="h-[20px] w-[auto]" src={f4} alt="eth" /> &nbsp; CAN'T SELL</p>
                                        <div className='w-[50%] flex items-center justify-end'>
                                            
                                            <span className='text-[15px] text-[#7b61ff]'>347</span>
                                        </div>
                                    </div>

                                    <div className='prog-bar flex items-center justify-between'>
                                        <p className='flex items-center text-[15px] text-[#E7E8DE] leading-[32px]'><img className="h-[20px] w-[auto]" src={f4} alt="eth" /> &nbsp; SIPHONED</p>
                                        <div className='w-[50%] flex items-center justify-end'>
                                            
                                            <span className='text-[15px] text-[#7b61ff]'>0</span>
                                        </div>
                                    </div>


                                    <div className='prog-bar flex items-center justify-between'>
                                        <p className='flex items-center text-[15px] text-[#E7E8DE] leading-[32px]'><img className="h-[20px] w-[auto]" src={f4} alt="eth" /> &nbsp; AVERAGE GAS</p>
                                        <div className='w-[50%] flex items-center justify-end'>
                                            
                                            <span className='text-[15px] text-[#7b61ff]'>153,111</span>
                                        </div>
                                    </div>
                                    <div className='prog-bar flex items-center justify-between'>
                                        <p className='flex items-center text-[15px] text-[#E7E8DE] leading-[32px]'><img className="h-[20px] w-[auto]" src={f4} alt="eth" /> &nbsp; SOURCE CODE</p>
                                        <div className='w-[50%] flex items-center justify-end'>
                                            <span className='text-[15px] text-[#7b61ff]'>OPEN SOURCE</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='text-center mt-[20px] md:mt-0  justify-center flex-col'>
                                <div className='w-full md:max-w-[366px] py-[13px] px-[14px] rounded-[19px] bg-[#2222268a] border border-[#34373F]'>
                                  
                                    <div className="flex justify-between gap-[14px] pb-[20px]">
                                        <nav className="flex space-x-4 bg-[#272727] rounded-md p-1" aria-label="Tabs">
                                            <a href="javascript:void(0);" className=" w-full bg-[#000000] text-[#E7E8DE] rounded-md px-3 py-2 text-sm font-medium" aria-current="page">Liquidity Pools</a>                   
                                        </nav>
                                    </div>
                                    <div className='text-left pb-4'>
                                        <div className='flex items-end justify-between'>
                                            <div className=''>
                                                <p className='text-[13px] mb-[10px] leading-[32px] font-regular text-[#E7E8DE] text-left flex gap-2 items-center'><img className='w-[30px] h-[30px]' src={uniswap} />Uniswap V3</p>
                                                <p className='text-[28px] text-[#E7E8DE] font-grok'>US$500k<span className='text-[17px] text-[#ffffff57] pl-2 font-normal'>90%</span></p>
                                            </div>
                                            <div className='flex items-center'>
                                            <button className="px-[15px] py-[1px] bg-[#878cfe38] text-[#b1b2ff] font-semibold rounded-md h-[40px] mr-2 text-[12px]">Trade Now</button>

                                                <img src={pp} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-left pb-2 '>
                                        <div className='flex items-end justify-between'>
                                            <div>
                                            <p className='text-[13px] mb-[10px] leading-[32px] font-regular text-[#E7E8DE] text-left flex gap-2 items-center'><img className='w-[30px] h-[30px]' src={susi} />SushiSwap</p>
                                                <p className='text-[28px] text-[#E7E8DE] font-grok'>US$30k<span className='text-[17px] text-[#ffffff57] pl-2 font-normal'>10%</span></p>
                                            </div>
                                            <div className='flex items-center'>
                                            <button className="px-[15px] py-[1px] bg-[#878cfe38] text-[#b1b2ff] font-semibold rounded-md h-[40px] mr-2 text-[12px]">Trade Now</button>
                                                <img src={pp1} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-[20px] w-full md:max-w-[366px] py-[13px] px-[14px] rounded-[19px] bg-[#2222268a] border border-[#34373F]'>
                               <div className='flex justify-between'>
                               <nav className="flex space-x-4 bg-[#272727] rounded-md p-1" aria-label="Tabs">
                                            <a href="javascript:void(0);" className=" w-full bg-[#000000] text-[#E7E8DE] rounded-md px-3 py-2 text-sm font-medium" aria-current="page">Price History</a>
                                        </nav>
                                        <p className='flex items-center gap-1 text-[#ff5f52] '>5.34%<svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label="down" className="text-[#ff5f52] Delta__StyledDownArrow-sc-7d13dc9d-1 flhNlw"><path d="M10.6979 16.2453L6.31787 9.75247C5.58184 8.66118 6.2058 7 7.35185 7L16.6482 7C17.7942 7 18.4182 8.66243 17.6821 9.75247L13.3021 16.2453C12.623 17.2516 11.377 17.2516 10.6979 16.2453Z" fill="currentColor"></path></svg></p>

                               </div>
                                    <div><img src={graph} /></div>
                                <div className='flex items-center justify-end mt-[20px]'>
                                    <div className="w-2/3 flex justify-end">
                                        <ul className='flex justify-end'>
                                        <li><button className='ml-[8px] text-[16px] font-[Inter] py-[4px] px-[6px] rounded-[10px] text-[#ffffff57] hover:bg-[#292929]'>1M</button></li>
                                        <li><button className='ml-[8px] text-[16px] font-[Inter] py-[4px] px-[6px] rounded-[10px] text-[#ffffff57] hover:bg-[#292929]'>15M</button></li>
                                        <li><button className='ml-[8px] text-[16px] font-[Inter] py-[4px] px-[6px] rounded-[10px] text-[#ffffff57] hover:bg-[#292929]'>30M</button></li>
                                        <li><button className='ml-[8px] text-[16px] font-[Inter] py-[4px] px-[6px] rounded-[10px] text-[#ffffff57] hover:bg-[#292929]'>1H</button></li>
                                            <li><button className='ml-[8px] text-[16px] font-[Inter] py-[4px] px-[6px] rounded-[10px] text-[#ffffff57] hover:bg-[#292929]'>4H</button></li>
                                            <li><button className='ml-[8px] text-[16px] font-[Inter] py-[4px] px-[6px] rounded-[10px] text-[#ffffff57] hover:bg-[#292929]'>1D</button></li>

                                            <li><button className='ml-[8px] text-[16px] font-[Inter] py-[4px] px-[8px] bg-[#292929] rounded-[10px] text-[#E7E8DE]'>7D</button></li>
                                        </ul>
                                    </div>   
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className='Medium_risk pt-[50px] md:pt-[100px] pb-[146px] '>
                            <div className='flex flex-col md:flex md:flex-row justify-between'>
                                <div className='order-last md:order-none text-left max-w-[600px]'>
                                    {/* <p className='font-bold text-md text-[#E7E8DE] font-[Inter]'>Gut für die Augen. Gut zum Mitnehmen</p> */}
                                    <p className='text-md text-[#E7E8DE] text-[#a1a1a6] font-medium leading-[26px] font-[Inter] pr-0 md:pr-[60px]'>{result?.tokenContext?.tokenInfo?.data?.info?.description }</p>
                                    <div className='tokenslist mt-6 font-[Inter]'>
                                        <ul>
                                            <li className='mb-[10px]'>
                                                <p className='text-[#a1a1a6]'> <strong className='font-medium text-[17px] text-[#E7E8DE]'>Token Name:</strong> </p>
                                                <button className='ml-[0px] mt-[10px] text-[16px] font-[Inter] py-[4px] px-[16px] bg-[#292929] rounded-full mb-[10px]'><span className='text-gradient font-bold'>{result?.tokenContext?.tokenInfo?.data?.name }</span></button>
                                            </li>
                                            <li className='mb-[10px]'>
                                                <p className='text-[#a1a1a6]'> <strong className='font-medium text-[17px] text-[#E7E8DE]'>Symbol:</strong></p>
                                                <button className='mt-[10px] text-[16px] font-[Inter] py-[4px] px-[16px] bg-[#292929] rounded-full mb-[10px]'><span className='text-gradient font-bold'>{result?.tokenContext?.tokenInfo?.data?.symbol }</span></button>
                                            </li>
                                            <li className='mb-[10px]'>
                                                <p className='text-[#a1a1a6]'> <strong className='font-medium text-[17px] text-[#E7E8DE]'>Chain:</strong></p>
                                                <button className='mt-[10px] text-[16px] font-[Inter] py-[4px] px-[16px] bg-[#292929] rounded-full mb-[10px]'><span className='text-gradient font-bold'>{result?.tokenContext?.tokenInfo?.data?.chain}</span></button>
                                            </li>
                                            <li className='mt-1 mb-[10px]' >
                                                <p className='text-[#a1a1a6]'> <strong className='font-medium text-[17px] text-[#E7E8DE] '>Contract address:</strong></p>
                                                <button onClick={() => copyToClipboard(result?.tokenContext?.tokenInfo?.data?.address)} className='flex items-center gap-3 mt-[10px] text-[11px] md:text-[16px] font-[Inter] py-[4px] px-[16px] bg-[#292929] rounded-full mb-[10px]'><span className='text-gradient font-bold'>{result?.tokenContext?.tokenInfo?.data?.address}</span>
                                                    <svg className='h-3 w-3 md:h-4 md:w-4'  xmlns="http://www.w3.org/2000/svg" version="1.1" width="512" height="512" x="0" y="0" viewBox="0 0 488.3 488.3" ><g><path d="M314.25 85.4h-227c-21.3 0-38.6 17.3-38.6 38.6v325.7c0 21.3 17.3 38.6 38.6 38.6h227c21.3 0 38.6-17.3 38.6-38.6V124c-.1-21.3-17.4-38.6-38.6-38.6zm11.5 364.2c0 6.4-5.2 11.6-11.6 11.6h-227c-6.4 0-11.6-5.2-11.6-11.6V124c0-6.4 5.2-11.6 11.6-11.6h227c6.4 0 11.6 5.2 11.6 11.6v325.6z" fill="#919192" opacity="1" data-original="#000000" ></path><path d="M401.05 0h-227c-21.3 0-38.6 17.3-38.6 38.6 0 7.5 6 13.5 13.5 13.5s13.5-6 13.5-13.5c0-6.4 5.2-11.6 11.6-11.6h227c6.4 0 11.6 5.2 11.6 11.6v325.7c0 6.4-5.2 11.6-11.6 11.6-7.5 0-13.5 6-13.5 13.5s6 13.5 13.5 13.5c21.3 0 38.6-17.3 38.6-38.6V38.6c0-21.3-17.3-38.6-38.6-38.6z" fill="#919192" opacity="1" data-original="#000000"></path></g></svg></button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='links mt-10'>
                                        <p className='text-[15px] pb-2 text-[#E7E8DE] font-light '>Links</p>
                                        <ul className='flex gap-2.5'>
                                            <li className='text-[#c59eb8] text-[13px] font-medium'><a>Etherscan ↗</a></li>
                                            <li className='text-[#c59eb8] text-[13px] font-medium'><a>Website ↗</a></li>
                                            <li className='text-[#c59eb8] text-[13px] font-medium'><a>Twitter ↗</a></li>
                                            <li className='text-[#c59eb8] text-[13px] font-medium'><a>Trade ↗</a></li>
                                        </ul>
                                        <div className='mt-10'>
                                    <p className='text-[#ffffff80] text-xs font-medium'>DYOR & Invest Wisely</p>
                                    </div>
                                    </div>
                                </div>
                                <div className='right_medium_list pr-[20px] text-left font-[Inter]'>
                                    <div className='mb-[40px]'>        <h1 className='font-semibold leading-none inline-block bg-gradient-to-r bg-gradient-to-r from-[#cbf2a2] to-[#cbf2a2] inline-block text-transparent bg-clip-text text-[48px] font-grok'>{result?.tokenContext?.credibilityInfo} <span className='text-[35px]'>Credibility</span></h1>
                                        <p className='text-[15px] text-[#E7E8DE] text-left font-light'>Investment Risk</p>
                                    </div>
                                    <div className='mb-[40px]'>        <h1 className='font-semibold leading-none inline-block bg-gradient-to-r bg-gradient-to-r from-[#7ba0ff] to-[#7ba0ff] inline-block text-transparent bg-clip-text text-[48px] font-grok'>{ formattedNumber(Math.floor(liquidity?.data?.metrics?.liquidity))}</h1>
                                        <p className='text-[15px] text-[#E7E8DE] text-left font-light'>Circulating Liquidity</p>
                                    </div>
                                    <div className='mb-[40px]'>        <h1 className='pb-2 font-semibold leading-none inline-block bg-gradient-to-r bg-gradient-to-r from-[#cf9cbe] to-[#cf9cbe] inline-block text-transparent bg-clip-text text-[48px] font-grok'>Daily </h1>
                                        <p className='text-[15px] text-[#E7E8DE] text-left font-light'>Twitter Community Engagement</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            
                    {/* <div className="App">
                    <Footerlinkstrans name="John" />
                </div> */}
                </div>
                </div>
        </>
    )
}
export default Credit4;