// import {WriteContract} from '@rainbow-me/wagmi'
import Headerfull from '../components/headerfull';
import ethsmallnew from "../images/ethnew.png";
import ardown from '../images/arrdown.svg';
import Footerlinksfixed from '../components/footerlinks';
import ethsmall from "../images/ethereum.webp";
import fuel from '../images/fuel 1.svg';
import uni from '../images/uni.png';
import { erc20ABI, useAccount, useBalance, useWaitForTransaction, useChainId, useWalletClient, useTransaction, useSendTransaction, useContractWrite, useContractRead, useSwitchNetwork, readContracts, useToken } from 'wagmi';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAccountModal, useChainModal, useConnectModal } from '@rainbow-me/rainbowkit';
import { LiFiWidget, WidgetConfig, WidgetWalletManagement, useWallet, WalletConnected, } from '@lifi/widget';
import { ChainId, ExecutionSettings, LiFi, RouteOptions, RoutesRequest, Token } from '@lifi/sdk'
import fetch, { Headers, Request, Response } from 'cross-fetch';
import axios from 'axios';
import { ethers } from 'ethers';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import fswap1 from '../images/fswap1.svg';
import coin from '../images/coins 2.svg';
import clock from '../images/clock 1.svg';
import layers from '../images/layers 1.svg';
import type { Wallet } from '@lifi/wallet-management';
import type { Signer } from '@ethersproject/abstract-signer';
import type { ChainTokenSelected, RouteContactSupport, RouteExecutionUpdate, RouteHighValueLossUpdate, } from '@lifi/widget';
import BigNumber from 'bignumber.js';
import search from "../images/search.svg";
import Decimal from 'decimal.js';
import { useWalletContext } from '../Provider/useContext';
import WalletModel from './walletModel';
import menub from "../images/menubars.svg";
import br1 from "../images/bri1.svg";
import righta from "../images/rightarr.svg";


interface wallet {
    isConnectModelOpen:boolean,
    isWalletConnect:boolean
}

export const Advancedswap = () => {


    const chainObj = {
        id: 0,
        image: '',
        name: '',

    }


    const {walletContext, setWalletContext} = useWalletContext() as any;
    const walletConextResp = useWalletContext() as any;
    const [searchTerm, setSearchTerm] = useState('');
    const [fromWei, setFromWei] = useState(0) as any;
    const [valueToConvert, setValue] = useState('');
    const [fromSelectedToken, fromSelectToken] = useState('');
    const [toSelectedToken, toSelectToken] = useState('');
    const [tokenAmount, setTokenAmount] = useState();
    const [toSelectedTokenAddress, toSelectTokenAddress] = useState('');
    const [fromTokenAmountAddress_, fromTokenAmountAddress] = useState('');
    const [slectedTokenSymbolTo, setTokenSymbolTo] = useState('');
    const [slectedTokenSymbolFrom, setTokenSymbolFrom] = useState('');
    const [toAmountInUsd, setAmount] = useState('');
    const [getReason, setReason] = useState('');
    const [isButtonDisable, setButtonDisable] = useState(false);
    const [feeInDollars, setFeeInDollar] = useState() as any;
    const [recieveTokens, setTokens] = useState() as any;
    const [approveHash, setApprovehash] = useState();
    const [fromTokenData, setTokenDataForFrom] = useState(null as any);
    const [toTokenData, setTokenDataForTo] = useState(null as any);
    const [showLoader, setShowLoader] = useState(false);
    const [fromAmountInBusd, setFromAmount] = useState(0);
    const [disableInput, setDisable] = useState(true);
    const [fromChainId, setFromChainId] = useState(chainObj);
    const [toChainId, setToChainId] = useState(chainObj);
    const [tokenDecimal, setTokenDecimal] = useState(0);
    const [openSetting, setSetting] = useState(false);
    const [route, setRoute] = useState(false);
    const [gas, setGasPrice] = useState(false);
    const [routeType, setRouteType] = useState('Recommended');
    const [gasType, setGasType] = useState('Normal');
    const [customSlippage, setSlippage] = useState(0.5);
    const [actualGasPrice, setActualGas] = useState('Normal');
    const [actualRoute, setActualRoute] = useState('RECOMMENDED');
    const [actualSlippage, setActualSlippage] = useState(0.05);
    const [toggleChecked, setDestinationToggle] = useState(true);
    const [bridges, setBridges_] = useState() as any;
    const [exhanges, setExchanges] = useState() as any;
    const [selectedBridges, setSelectBridge] = useState();
    const [selectedExhanges, setSelectExchanges] = useState();
    const [isBridgeOpen, setBridgeOpen] = useState(false);
    const [isExchageOpen, setExchangeOpen] = useState(false);
    const [openAccordian, setAccordian] = useState(false);
    const [exchnageBtn, setExchangeBtn] = useState(false);

    function accordian(bool: boolean) {
        setAccordian(!bool);
    }
    function connectWalletClicked() {
        const wallet: wallet = {
            isConnectModelOpen: true,
            isWalletConnect:false,
        }
        setWalletContext(wallet);
    }


    // initializing toaster
    const showToast = () => {
        toast.success('Hello, this is a toast message!', {
            position: 'top-right',
            autoClose: 3000, // Set the duration for how long the toast should be visible
        });
    };

    // END

    // Toggle functionality for setting model

    function toggleSettingModel(value: boolean) {
        setSetting(!value);
    }

    // End
    //Destination Togele
    function destinationToggel(isChecked: boolean) {
        setDestinationToggle(!toggleChecked);
    }
    // ToggleRoute

    function showRouteOption(route: boolean) {
        setRoute(!route);
    }
    // End

    // Toggle gasPrice option 

    function showGasOption(gasPrice: boolean) {
        setGasPrice(!gasPrice);
    }

    // end

    // getting tools 
    const getTools = async () => {

        const result = await axios.get('https://li.quest/v1/tools');
        return result.data;

    }

   

    useEffect(() => {
        const fetchData = async () => {
            try {
                const toolsData = await getTools();
                setBridges_(toolsData?.bridges);
                setExchanges(toolsData?.exchanges);
            } catch (error) {
                // Handle error here
                console.error("Error in fetchData:", error);
            }
        };

        fetchData();
    }, [])

    // End
    const getToken = async (chain: number, token: string) => {
        try {
            const result = await axios.get('https://li.quest/v1/token', {
                params: {
                    chain,
                    token,
                }
            });
            return result.data;
        } catch (error) {
            console.error('Error fetching token:', error);
            // throw new Error('Error fetching token');
        }
    };

    useEffect(() => {
        // alert('hi')
        if (fromTokenAmountAddress_ !== '' || slectedTokenSymbolFrom !== '' || slectedTokenSymbolTo !== '') {
            setDisable(false);
            if (slectedTokenSymbolFrom === '' && slectedTokenSymbolTo === '') {
                setDisable(true);
            }
            const result = async () => {

                const data = await getToken(chainId, fromTokenAmountAddress_);
                await setTokenDecimal(await data?.decimals);
                await convertToWei(valueToConvert, await data?.decimals);

            }
            result();
        }
    }, [fromTokenAmountAddress_, slectedTokenSymbolFrom, slectedTokenSymbolTo, fromWei,])
    const lifi = new LiFi({
        integrator: 'objects'
    })

    const configUpdate = {
        apiUrl: 'https://li.quest/v1',
        apiKey: '2112b51d-e8e9-4b89-a09d-e42fe17b6767.d4a011ff-088d-4fa8-a68b-d7dea61ee581',
    }

    lifi.setConfig(configUpdate);
    if (!globalThis.fetch) {
        const globalThisAny: any = globalThis
        globalThisAny.fetch = fetch
        globalThisAny.Headers = Headers
        globalThisAny.Request = Request
        globalThisAny.Response = Response
    }

    const useAccount_ = useAccount();
    const chainId = useChainId();
    const { openChainModal } = useChainModal();
    const useAccountBalance = useBalance({ address: useAccount_.address });
    const { chains, switchNetwork } = useSwitchNetwork() as any;

    // Here i am fincding the specificm chain object 
    const [networkName_1, setChainOne] = useState(1) as any;
    const [networkName_2, setChainSecond] = useState(1) as any;

    const networkName = (chains: any) => {
        const foundChain = chains.find((chains: any) => chains.id === chainId);
        if (foundChain) {
            setChainOne(foundChain);
            return foundChain;
        } else {
            return '';
        }
    }

    const networkNameSecound = (chains: any) => {
        const foundChain = chains.find((chains: any) => chains.id === chainId);
        if (foundChain) {
            setChainSecond(foundChain);
            return foundChain;
        } else {
            return '';
        }
    }

    useEffect(() => {
        if (useAccount_.status === 'disconnected') {
            setFromChainId({ id: 0, image: '', name: '' });
            setToChainId({ id: 0, image: '', name: '' });
        }
        
    },[useAccount_])
    // End

    // const networkName_1 = networkName(chains);
    // const networkName_2 = networkNameSecound(chains);

    // let fetchTokensData;
    let address: any;


    useEffect(() => {
        const fetchData = async () => {
            if (fromChainId?.id) {

                try {
                    const response = await fetch(`https://li.quest/v1/tokens?chains=${fromChainId?.id}`, {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            // Add any other headers if needed
                        },
                    });

                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }

                    const data = await response.json();
                    console.log("🚀 ~ fetchData ~ data:", data)

                    setTokenDataForFrom(data);
                } catch (error) {
                    console.error('Error fetching data:', error);
                    // Handle error, show an error message, etc.
                }
            };
        }
        fetchData();
       
    }, [fromChainId]); // Run only once when the component mounts

    useEffect(() => {
         setTokenSymbolFrom('');
        setTokenSymbolTo('');
        setValue('');
        
    },[chainId])
    useEffect(() => {
        const fetchData = async () => {
            if (toChainId?.id) {

                try {
                    const response = await fetch(`https://li.quest/v1/tokens?chains=${toChainId?.id}`, {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            // Add any other headers if needed
                        },
                    });

                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }

                    const data = await response.json();

                    setTokenDataForTo(data);
                } catch (error) {
                    console.error('Error fetching data:', error);
                    // Handle error, show an error message, etc.
                }
            };
        }
        fetchData();
    }, [toChainId]); // Run only once when the component mounts


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isBridgesOpen, setBridges] = useState(false) as any;
    const [isBridgesOpen_1, setBridges_1] = useState(false) as any;



    const openModal = () => {
        if (useAccount_.status === 'connected') {
            setIsModalOpen(true);
        } else {
            // toast.error('Please connect your wallet')
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const bridgeOpen = () => {
        if (useAccount_.status === 'connected') {
            setBridges(true);
        } else {
            // toast.error('Please connect your wallet')

        }
    };

    const bridgeClosed = () => {
        setBridges(false);
    };

    const bridgeOpen_1 = () => {
        if (useAccount_.status === 'connected') {
            setBridges_1(true);
        } else {
            toast.error('Please connect your wallet')

        }
    };

    const bridgeClosed_1 = () => {
        setBridges_1(false);
    };

    const [isModalOpenTo, setIsModalOpenTo] = useState(false);
    const [openWallet, setWalletModel] = useState(false);


    const openModalTo = () => {
        if (useAccount_.status === 'connected') {
            setIsModalOpenTo(true);
        } else {
            toast.error('Please connect your wallet')
        }
    };

    const closeModalTo = () => {
        setIsModalOpenTo(false);
    };




    function getFromChainId(fromChainId: any, image: any, name: '') {
        if (fromChainId === chainId) {
            const fromChainIdObj = {
                id: fromChainId,
                image: image,
                name: name,
            }
            setFromChainId(fromChainIdObj);
            setTokenSymbolFrom('');
        } else {
            // alert('hi')
            // switchNetwork({ chainId: fromChainId })
            const fromChainIdObj = {
                id: fromChainId,
                image: image,
                name: name,
            }
            setFromChainId(fromChainIdObj);
            setTokenSymbolFrom('');
        }
    }

    function getToChainId(toChainId: any, image: any, name: '') {
        const toChainIdObj = {
            id: toChainId,
            image: image,
            name: name,
        }
        setToChainId(toChainIdObj);
        setTokenSymbolTo('');
    }

    const tokensFrom = Object.keys(fromTokenData?.tokens ?? {})
        .flatMap((tokenId) => fromTokenData?.tokens[tokenId] ?? []);

    // // Filter tokens based on search term
    const filteredTokensFrom = tokensFrom.filter((token) =>
        token && token?.symbol.toLowerCase() && token?.symbol?.includes(searchTerm.toLowerCase()) ||
        token && token?.symbol && token?.symbol?.includes(searchTerm)
    );


    const handleSearchChange = (e: any) => {
        setSearchTerm(e.target.value);
    };

    const renderTokensFrom = () => {
        return filteredTokensFrom.map((token: any) => (
            <span>
                <div className="flex  mb-1.5" onClick={async () => {
                    await getTokenAddressFrom(token.logoURI, token.address, token.symbol);
                    closeModal();
                }} key={token?.address} >
                    <img className='w-[40px] h-[40px] rounded-full' src={token.logoURI} />
                    <div className='text-left ml-2'>
                        <p className='text-[16px] leading-[22px] font-semibold text-[#ffffff] text-left flex gap-2 items-center uppercase'>{token.symbol}</p>
                        <p className='text-[12px]  text-[#B1B1B1]'>{token.name}</p>
                    </div>
                </div>
            </span>
        ))
    };

    const tokensTo = Object.keys(toTokenData?.tokens ?? {})
        .flatMap((tokenId) => toTokenData?.tokens[tokenId] ?? []);

    // // Filter tokens based on search term
    const filteredTokensTo = tokensTo.filter((token) =>
        token && token?.symbol.toLowerCase() && token?.symbol?.includes(searchTerm.toLowerCase()) ||
        token && token?.symbol && token?.symbol?.includes(searchTerm)
    );
    const renderTokensTo = () => {
        return filteredTokensTo.map((token: any) => (
            <span>


                <div className="flex  mb-1.5" onClick={async () => {
                    await getTokenAddressTo(token.logoURI, token.address, token.symbol);
                    closeModalTo();
                }} key={token?.address} >
                    <img className='w-[40px] h-[40px] rounded-full' src={token.logoURI} />
                    <div className='text-left ml-2'>
                        <p className='text-[16px] leading-[22px] font-semibold text-[#ffffff] text-left flex gap-2 items-center uppercase'>{token.symbol}</p>
                        <p className='text-[12px]  text-[#B1B1B1]'>{token.name}</p>
                    </div>
                </div>
            </span>
        ));
    };

    const getTokenAddressFrom = (img: any, address: any, symbol: any) => {
        fromSelectToken(img);
        fromTokenAmountAddress(address);
        setTokenSymbolFrom(symbol);

        console.log('eventAddress', address)
    }
    const getTokenAddressTo = (img: any, address: any, symbol: any) => {
        toSelectToken(img);
        toSelectTokenAddress(address);
        setTokenSymbolTo(symbol);
        console.log('eventAddress', address)
    }

    // lifi api integration
    const setTokenAmount_ = (amount: any) => {
        setTokenAmount(amount.target.value);
    }

   

    const convertToWei = (value: any, decimal: any) => {
        // alert('hi')
        console.log('weiVal', decimal)
        if (value === '') {
            inDecimal_1(0)
            inDecimal(0);
            setFromAmount(0);
            setAmount('');
            routesResponse();
            setShowLoader(false);
            setButtonClicked(false);
        }
        setButtonClicked(false);
        const decimalFactor = 10 ** (decimal ? decimal : 18);
        const wei = (parseFloat(value !== '' ? value : 0) * decimalFactor as any);
        setValue(value);
        // Update the state with the converted value
        setFromWei(wei);
        console.log('weivalue1', wei)
        return wei;
        // }
    };
    console.log("🚀 ~ fromWei:", fromWei)

    type RouteOptions = {
        slippage: number;
        order: "RECOMMENDED" | "FASTEST" | "CHEAPEST" | "SAFEST";
        maxPriceImpact: number,
    };

    const routeOptions = {
        slippage: Number(actualSlippage), // 3%
        order: String(actualRoute),
        maxPriceImpact: 1,
    }

    const routesRequest_1 = {
        fromChainId: Number(fromChainId?.id),
        fromAmount: String(fromWei), // 1USDT
        fromTokenAddress: String(fromTokenAmountAddress_),
        toChainId: Number(toChainId?.id),
        toTokenAddress: String(toSelectedTokenAddress),
        options: routeOptions,
    }

    let result: any;
    const [routesResult, setRoutes] = useState<any>();

    console.log("🚀 ~ routesResult:", routesResult)

    const [inDecimal_, getInDecimal] = useState();
    function inDecimal(number: any) {
        // console.log('routessss',routes)
        const etherAmount = number / Math.pow(10, 18);
        // getInDecimal(etherAmount as any)
        console.log("🚀 ~ etherAmount:", etherAmount)
        return etherAmount;
    }

    function inDecimal_1(number: any) {
        // console.log('routessss',routes)
        const etherAmount = number / Math.pow(10, 18);
        getInDecimal(etherAmount as any)
        console.log("🚀 ~ etherAmount:", etherAmount)
        return etherAmount;
    }
    const [gasCost, getGasCost] = useState();
    let quote: any

    useEffect(() => {
        if (useAccount_.status === 'disconnected') {
            setButtonDisable(true);
            setTokenSymbolFrom('');
            setTokenSymbolTo('');
            convertToWei(0, tokenDecimal);
            routesResponse();
            inDecimal_1(0);
            setShowLoader(false);
        } else if (useAccount_.status === 'connected' && slectedTokenSymbolFrom !== '' && slectedTokenSymbolTo !== '' && fromWei !== '') {
            setButtonDisable(false);
        } else {
            setButtonDisable(true);

        }
    }, [useAccount_, slectedTokenSymbolFrom, slectedTokenSymbolTo, fromWei])
    useEffect(() => {
        console.log('fromwei', fromWei)
        if (fromWei !== '' && slectedTokenSymbolTo !== '' && slectedTokenSymbolFrom !== '' && fromChainId?.id !== 0 && toChainId?.id !== 0) {
            fetchData(index)
        }

    }, [fromWei, slectedTokenSymbolTo, slectedTokenSymbolFrom, fromChainId, toChainId])
    function scientificToDecimal(scientificNotation: string, digitsToMove: number): string {

        const scientificNotationRegex = /^[+-]?\d+(\.\d*)?[eE][+-]?\d+$/;
        const isNumberScientific = scientificNotationRegex.test(scientificNotation);
        console.log("🚀 ~ scientificToDecimal ~ isNumberScientific:", isNumberScientific)
        console.log('type of', typeof valueToConvert)
        console.log('scientificNumber', scientificNotation)
        const decimalNumber = new Decimal(scientificNotation);

        // Move the decimal point to the desired position
        let result;
        if (isNumberScientific) {
            result = decimalNumber.times('1e' + 12);
            console.log("🚀 ~ scientificToDecimal ~ digitsToMove:", 12)
            console.log("🚀 ~ scientificToDecimal ~ aftermoving:", result)
        } else {
            result = decimalNumber.times('1e' + 0);
        }
        return result.toFixed(10);
    }

    const [routesResultResponse, routesResponse] = useState() as any;
    const [toolLogo, setToolLogo] = useState() as any;
    const [toolName, setToolName] = useState() as any;
    const [toolImg, getToolImage] = useState();
    const [toolName_, getToolName] = useState();
    const [index, setIndex] = useState(0);
    function getIndex(index: any, toolLogo: any, toolSymbol: any) {
        console.log('advance index', index)
        setIndex(index ? index : 0);
        fetchData(index);
        setToolLogo(toolLogo);
        setToolName(toolSymbol);
    }
    console.log("🚀 ~ routesResultResponse:", routesResultResponse)
    async function fetchData(index: any) {
        if (valueToConvert !== "" && useAccount_.status === 'connected') {
            if (valueToConvert) {
                setShowLoader(true);
            }
            try {
                result = await lifi.getRoutes(routesRequest_1 as any);
                inDecimal_1(result.routes[index]?.toAmount);
                setAmount(result.routes[index]?.toAmountUSD);
                getGasCost(result.routes[index]?.gasCostUSD);
                setFromAmount(result?.routes[index]?.fromAmountUSD);
                getToolImage(result?.routes[0].steps[0]?.toolDetails?.logoURI);
                getToolName(result?.routes[0]?.steps[0]?.toolDetails?.name);
                const feeInDollar = ((scientificToDecimal((String(inDecimal_ ? inDecimal_ : 0 * 0.15 / 100)) as any, 4)));
                console.log("🚀 ~ fetchData ~ feeInDollar:", scientificToDecimal(String(result?.routes[0]?.toAmount), 4))
                setFeeInDollar(feeInDollar);
                routesResponse(result);
                setShowLoader(false);
                // if(result.unavailableRoutes ){
                setReason(result?.unavailableRoutes?.filteredOut[0]?.reason)
                if (getReason !== '') {
                    setButtonDisable(true);
                } else {
                    setButtonDisable(false);
                }
                await setExchangeBtn(false);

                // }
                console.log("🚀 ~ fetchData ~ setReason:", getReason)
                return result;
                console.log("🚀 ~ routes:", result.routes[0]?.toAmount);
            } catch (error) {
                await setExchangeBtn(false);
                setShowLoader(false);
                console.error('Error:', error);
                if (fromWei !== 0) {
                    toast.error('Routes not found.')
                }
            }
        }
    }


    const [buttonClicked, setButtonClicked] = useState(false);
    console.log("🚀 ~ buttonClicked:", buttonClicked)

    const [quote_, setQuote] = useState(null); // Assuming quote is a state variable
    // let wallet:any;

    const getQuote = async (fromChain: any, toChain: any, fromToken: any, toToken: any, fromAmount: any, fromAddress: any) => {

        try {
            const result = await axios.get('https://li.quest/v1/quote', {
                params: {
                    fromChain,
                    toChain,
                    fromToken,
                    toToken,
                    fromAmount,
                    fromAddress,
                },
            })

                ;
            return result.data;
        } catch (error) {
            console.log('qouteError', error)
            throw new Error(`Error fetching quote:`);
        }
    }
    console.log("🚀 ~ getQuote ~ getQuote:", getQuote)


    // tranaction execute here

    function handleButtonClick() {
        setButtonClicked(true);
    };

    const [lifiResp, setResponse] = useState() as any;
    console.log("🚀 ~ lifiResp:", lifiResp)
    const { data } = useWalletClient();
    console.log("🚀 ~ useWallet:", data?.sendTransaction)
    console.log("🚀 ~ result:", result)

    const tokenAddress = fromTokenAmountAddress_;
    console.log("🚀 ~ Advancedswap ~ tokenAddress:", tokenAddress)


    const walletAddress = useAccount_?.address?.slice(2);
    const fromTokenAddress = fromTokenAmountAddress_?.slice(2);
    console.log("🚀 ~ Advancedswap ~ fromTokenAddress:", fromTokenAddress)
    console.log("🚀 ~ Advancedswap ~ walletAddress:", walletAddress)
    let payload = {
        address: (`0x${fromTokenAddress}`),
        abi: erc20ABI,
        functionName: "allowance",
        args: [(`0x${walletAddress}`), '0x1231DEB6f5749EF6cE6943a275A1D3E7486F4EaE'],
        chainId: chainId,
    }
    console.log("🚀 ~ Advancedswap ~ payload:", payload)

    const useTokenBalance = useBalance({
        address: useAccount_.address,
        token: `0x${fromTokenAddress}`,
        chainId: chainId,
    });

    const useWalletBalace = useBalance({
        address: useAccount_.address,
    })

    // console.log("🚀 ~ Advancedswap ~ tokenBalance:", useTokenBalance)
    const checkAllowance = useContractRead({
        address: (`0x${fromTokenAddress}`),
        abi: erc20ABI,
        functionName: "allowance",
        args: [(`0x${walletAddress}`), '0x1231DEB6f5749EF6cE6943a275A1D3E7486F4EaE'],
        chainId: chainId,
    });

    const approvalAmount: any = useContractWrite({
        address: (`0x${fromTokenAddress}`),
        abi: erc20ABI,
        functionName: "approve",
        args: ['0x1231DEB6f5749EF6cE6943a275A1D3E7486F4EaE', BigInt(`${fromWei}`)],
        chainId: chainId,
        onSuccess(resp: any) {
            setApprovehash(resp.hash);
            console.log('Success', resp)

        },
        onError(err) {
            console.log(err)
        },
        onSettled(data, error, variables, context) {
            console.log('execution', quote_)
            console.log('data', data)
            setTimeout(() => {
                getExecution(quote_);

            }, 12000);
        },

    });
    // console.log("🚀 ~ Advancedswap ~ write:", result__1)


    function addCommas(numberString: string): string {
        return numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const getExecution = async (res: any) => {
        console.log("🚀 ~ getExecution ~ res:", res)

        try {


            const tx = await data?.sendTransaction(res?.transactionRequest);

            if (tx !== undefined) {
                setResponse(tx);
            }
            setTimeout(() => {
                console.log("🚀 ~ getExecution ~ result:", result)
                console.log("🚀 ~ getExecution ~ tx:", tx)

                toast.success('Transaction Successfull')
                setButtonClicked(false);
                setTokenSymbolFrom('');
                setTokenSymbolTo('');
                convertToWei(0, tokenDecimal);
                inDecimal_1(0);
                setShowLoader(false);
                routesResponse();
                setSwapText("Swap");
            }, 11000);


        } catch (error: any) {
            setTimeout(() => {
                setShowLoader(false);
                setTokenSymbolFrom('');
                setTokenSymbolTo('');
                convertToWei(0, tokenDecimal);
                inDecimal_1(0);
                routesResponse();
                setButtonClicked(false);
            }, 3000);
            setValue('');
            setFromAmount(0);
            setAmount('');
            toast.error('unSuccessfull:', error)
            setSwapText("Swap");
            console.error('Error executing transaction:', error?.error?.message);
        }
    };

    useEffect(() => {


        if (buttonClicked && (useWalletBalace?.data?.formatted !== undefined && useWalletBalace?.data?.formatted > valueToConvert ||
            useTokenBalance?.data?.formatted !== undefined && ((useTokenBalance?.data?.formatted) as any > valueToConvert))) {

            const fromChain = fromChainId?.id;
            const toChain = toChainId?.id;
            const fromToken = slectedTokenSymbolFrom;
            const toToken = slectedTokenSymbolTo;
            const fromAmount = fromWei;
            const fromAddress = useAccount_.address;


            quote = getQuote(fromChain, toChain, fromToken, toToken, fromAmount, fromAddress)
                .then(res => { console.log('getQuote', res) });

            // const provider = new ethers.providers.JsonRpcProvider(`${networkName_1?.rpcUrls?.default.http}`, networkName_1.id);

            getQuote(fromChain, toChain, fromToken, toToken, fromAmount, fromAddress)
                .then(async (res) => {
                    console.log('getQuote', res);

                    setQuote(res);
                    const allowanceData = checkAllowance?.data;
                    const ethValue: any = allowanceData ? Number(allowanceData) / 10 ** 18 : 0;
                    console.log("🚀 ~ .then ~ ethValue:", ethValue)
                    console.log("🚀 ~ useEffect ~ fromAmount:", fromAmount)

                    if (ethValue < fromAmount) {

                        const whatisthis = await approvalAmount.write()
                        console.log("what", whatisthis)

                    } else {
                        getExecution(res)
                    }


                    console.log("🚀 ~ .then ~ allowanceData:", ethValue)

                    // fetchData_1();


                })
                .catch((error) => console.error('Error fetching quote:', error.message));

        } else if (buttonClicked === true && useTokenBalance?.data?.formatted !== undefined && useTokenBalance?.data?.formatted < valueToConvert) {
           setButtonDisable(false);
            setButtonClicked(false);
            setTokenSymbolFrom('');
            setTokenSymbolTo('');
            setShowLoader(false);
            setValue('');
            setFromAmount(0);
            setAmount('');
            inDecimal(0);
            setDisable(true);
            toast.error(`You dont have enough ${useTokenBalance?.data?.symbol} in your wallet`);
        } else if (buttonClicked === true && useWalletBalace?.data?.formatted !== undefined && useWalletBalace?.data?.formatted < valueToConvert) {
            // alert(buttonClicked)
            toast.error(`You dont have enough ${useWalletBalace?.data?.symbol} in your wallet`);
           setButtonDisable(false);
            setButtonClicked(false);
            setTokenSymbolFrom('');
            setTokenSymbolTo('');
            setShowLoader(false);
            setValue('');
            setFromAmount(0);
            setAmount('');
            inDecimal(0);
            setDisable(true);

        }



    }, [buttonClicked]); // Only run when buttonClicked changes

    const transactionResult = useTransaction({ hash: '0x0f2e50c61ebc309d50062b49e5ec60392973410dee7a1abcd4e1b95a7bf78175' });
    console.log("🚀 ~ transactionResult:", transactionResult)

    //  END//////////////////////////////////////////////////////////////////////


    // function to convert big number to decimal

    function bigNumber(value: any, decimalPlaces = 0) {
        // const bigDecimal = parseInt(value, 0);
        const bigDecimal = new BigNumber(value);
        const decimalValue = bigDecimal.dividedBy(new BigNumber(10).exponentiatedBy(decimalPlaces));

        return decimalValue.toString();
    }

    // end
    const [activeAccordion, setActiveAccordion] = useState(null);
    const toggleAccordion = (accordionId: any) => {
        if (activeAccordion === accordionId) {
            setActiveAccordion(null);
        } else {
            setActiveAccordion(accordionId);
        }
    };

    const cutomColor = {
        color: '#ff5555',
    }

    function isWalletConnect() {
        if (useAccount_.status === 'disconnected') {
            toast.error('Please connect your wallet');
        }
    }

    function exchangeToken() {
        // console.log('tokenIndecimal',tokenDecimal)
        if (!exchnageBtn) {
            setFromWei('');
            setTokenSymbolFrom(slectedTokenSymbolTo);
            setTokenSymbolTo(slectedTokenSymbolFrom);
            fromSelectToken(toSelectedToken);
            toSelectToken(fromSelectedToken);
            fromTokenAmountAddress(toSelectedTokenAddress);
            toSelectTokenAddress(fromTokenAmountAddress_);
            setFromChainId(toChainId);
            setToChainId(fromChainId);
            setExchangeBtn(true);
        }
    }
    //End

    function getRouteType(type: any) {
        setRouteType(type);
    }

    function getGasType(type: string) {

        setGasType(type);
    }

    function Slippage(value: any) {
        setSlippage(value)
    }

    function saveSetting() {
        setActualGas(gasType);
        setActualRoute(routeType);
        setActualSlippage(customSlippage);
        fetchData(index);
    }

    function openBridgesModel(bridge: boolean) {
        setBridgeOpen(!isBridgeOpen);
        setExchangeOpen(false);
    }

    function openExchangesModel(exchange: boolean) {
        setExchangeOpen(!isExchageOpen);
        setBridgeOpen(false);
    }

    const [swapText, setSwapText] = useState('Swap');
    const lifiResp_ = lifiResp?.value?._hex;
    const tokenbalance = useTokenBalance?.data?.formatted;

    useEffect(() => {
        if (showLoader && !buttonClicked && slectedTokenSymbolTo !== "") {
            setSwapText('Fetching Best Prices...');
        } else if (buttonClicked) {
            setSwapText('Processing...');
        } else {
            setSwapText('Swap');
        }
    }, [showLoader,  buttonClicked , slectedTokenSymbolTo])
    
    return (
        <>
            {/* <div> */}
            {/* <button onClick={showToast}></button> */}
            <ToastContainer />
            {/* </div> */}

            <div className='main min-h-screen'>
                <div className="App">
                    <Headerfull name="John" />
                </div>

                {!openSetting && !isModalOpen && !isModalOpenTo && !isBridgesOpen && !isBridgesOpen_1 && !walletConextResp?.walletContext?.isConnectModelOpen && (
                    <div className='pt-[100px] md:pt-[140px]'>
                        <div className='mx-auto px-[20px] sm:px-[16px] lg:px-[16px] w-full xl:w-10/12 text-center'>
                            <div className='option box mt-8 pb-[100px] mt-[0px] flex gap-10 justify-center items-start'>
                                <div className='border border-[#34373F] bg-[#2222268a]  w-full sm:w-8/12 xl:w-5/12 rounded-2xl p-[10px] sm:p-5'>
                                    <div className='choose head px-[12px] md:pt-0 pt-[3px] pb-[15px] md:pb-5 flex items-center justify-between'>
                                        <div className='flex gap-4'><p className='text-left text-[#E7E8DE] text-md font-semibold'>Swap</p><p className='ml-[10px] text-left text-[#9b9b9b] text-md font-semibold'>Buy</p></div>
                                        <div className='flex gap-4 items-center'>
                                            <svg className='cursor-pointer' width="20" onClick={() => fetchData(index)} height="20" xmlns="http://www.w3.org/2000/svg" version="1.1" x="0" y="0" viewBox="0 0 6.35 6.35"><g><path d="M3.276.387a2.795 2.795 0 0 0-2.89 2.689 2.796 2.796 0 0 0 2.69 2.889.265.265 0 1 0 .018-.53 2.256 2.256 0 0 1-2.178-2.34 2.257 2.257 0 0 1 2.34-2.18 2.256 2.256 0 0 1 2.178 2.34 2.25 2.25 0 0 1-.737 1.59l.09-.537a.265.265 0 0 0-.273-.31.265.265 0 0 0-.248.223L4.08 5.328a.265.265 0 0 0 .217.304l1.108.186a.265.265 0 1 0 .087-.522l-.428-.071a2.783 2.783 0 0 0 .9-1.95A2.794 2.794 0 0 0 3.275.387z" paint-order="stroke fill markers" fill="#9ca3af" opacity="1" data-original="#000000"></path></g></svg>
                                            <svg className=" cursor-pointer h-7 w-7 text-[#9ca3af]" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <line x1="12" y1="5" x2="12" y2="19" />  <line x1="5" y1="12" x2="19" y2="12" /></svg>
                                            <svg onClick={() => toggleSettingModel(openSetting)} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="cursor-pointer text-gray-400 curso-pointer MenuButton__Icon-sc-7e635c1d-0 dLgZFm"><path d="M20.83 14.6C19.9 14.06 19.33 13.07 19.33 12C19.33 10.93 19.9 9.93999 20.83 9.39999C20.99 9.29999 21.05 9.1 20.95 8.94L19.28 6.06C19.22 5.95 19.11 5.89001 19 5.89001C18.94 5.89001 18.88 5.91 18.83 5.94C18.37 6.2 17.85 6.34 17.33 6.34C16.8 6.34 16.28 6.19999 15.81 5.92999C14.88 5.38999 14.31 4.41 14.31 3.34C14.31 3.15 14.16 3 13.98 3H10.02C9.83999 3 9.69 3.15 9.69 3.34C9.69 4.41 9.12 5.38999 8.19 5.92999C7.72 6.19999 7.20001 6.34 6.67001 6.34C6.15001 6.34 5.63001 6.2 5.17001 5.94C5.01001 5.84 4.81 5.9 4.72 6.06L3.04001 8.94C3.01001 8.99 3 9.05001 3 9.10001C3 9.22001 3.06001 9.32999 3.17001 9.39999C4.10001 9.93999 4.67001 10.92 4.67001 11.99C4.67001 13.07 4.09999 14.06 3.17999 14.6H3.17001C3.01001 14.7 2.94999 14.9 3.04999 15.06L4.72 17.94C4.78 18.05 4.89 18.11 5 18.11C5.06 18.11 5.12001 18.09 5.17001 18.06C6.11001 17.53 7.26 17.53 8.19 18.07C9.11 18.61 9.67999 19.59 9.67999 20.66C9.67999 20.85 9.82999 21 10.02 21H13.98C14.16 21 14.31 20.85 14.31 20.66C14.31 19.59 14.88 18.61 15.81 18.07C16.28 17.8 16.8 17.66 17.33 17.66C17.85 17.66 18.37 17.8 18.83 18.06C18.99 18.16 19.19 18.1 19.28 17.94L20.96 15.06C20.99 15.01 21 14.95 21 14.9C21 14.78 20.94 14.67 20.83 14.6ZM12 15C10.34 15 9 13.66 9 12C9 10.34 10.34 9 12 9C13.66 9 15 10.34 15 12C15 13.66 13.66 15 12 15Z" fill="currentColor"></path></svg></div>
                                    </div>
                                    <div className='relative option-content rounded-2xl w-full p-0.5 back-gradient'>
                                        <div className='bg-[#000] p-1 rounded-2xl'>
                                            <div className='relative you-pay bg-[#272727] rounded-2xl border-transparent p-4 hover:border-gray-700'>
                                                <div className='flex items-center justify-between gap-4'>
                                                    <div className="">
                                                        <label className="block text-left text-sm font-medium  text-[#9b9b9b]">You Pay</label>
                                                        <input type="text" disabled={disableInput} value={valueToConvert} name="number"
                                                            onChange={(e) => convertToWei(e.target.value, tokenDecimal)} autoComplete='off' id="number"
                                                            className={`mt-1 mr-4 block px-0 text-[22px] leading-[22px] sm:text-[32px] bg-transparent w-full rounded-md border-0 py-0.5 shadow-0 ring-1 ring-transparent ring-gray-300 ${disableInput ? 'text-transparent placeholder-transparent' : 'text-[#E7E8DE] placeholder:text-[#E7E8DE] focus:ring-transparent sm:leading-1'} ${valueToConvert === '' ? 'placeholder:text-[#1b1b1a] text-[#525151]' : ''}`}
                                                            placeholder="0" />

                                                        <button disabled={exchnageBtn}  onClick={() => exchangeToken()}>
                                                        <div className='p-2 flex items-center cursor-pointer bg-[#272727] border-black border-4 rounded-xl absolute left-[46%] top-[87%]'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                                    <line x1="12" y1="5" x2="12" y2="19"></line><polyline points="19 12 12 19 5 12"></polyline></svg>
                                                        </div>
                                                                </button>

                                                    </div>
                                                    <div>

                                                    </div>
                                                </div>
                                                <div className='flex items-center justify-between gap-2'>
                                                    <div>
                                                        <button onClick={bridgeOpen} className=" text-lg text-[#9b9b9b] font-no rmal pl-[7px] pr-[9px] py-[2px] bg-[#000000] border border-transparent rounded-lg flex items-center gap-2" type="button">
                                                            {fromChainId?.name  && useAccount_.status === 'connected' ? (
                                                                <p className="text-[14px] flex items-center" onClick={() => isWalletConnect()}>
                                                                    <img className="w-6 h-6 p-[4px]" src={fromChainId.image ? fromChainId.image : ethsmall} alt="eth" />
                                                                    {fromChainId?.name ? fromChainId.name : 'Ethereum'}
                                                                </p>
                                                            ) : (
                                                                <p className="mr-2 xl:mr-1 text-[18px] text-gradient" onClick={() => isWalletConnect()}>Select Chain</p>
                                                            )}
                                                            <svg className="-mr-1 h-5 w-5 text-[#9b9b9b]" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                                <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd">
                                                                </path>
                                                            </svg>
                                                        </button>
                                                        <div id="dropdown119" className="z-10 bg-black divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 hidden" data-popper-placement="bottom"><ul className="py-2 px-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton119"><li><a href="#" className="block px-4 py-2 hover:bg-[#212125] text-white rounded-md dark:hover:bg-gray-600 dark:hover:text-[#E7E8DE]">Account settings</a></li><li><a href="#" className="text-[#E7E8DE] block px-4 py-2 hover:bg-gray-100 rounded-md dark:hover:bg-gray-600 dark:hover:text-[#E7E8DE]">Support</a></li><li><a href="#" className="text-[#E7E8DE] block px-4 py-2 hover:bg-gray-100 rounded-md dark:hover:bg-gray-600 dark:hover:text-[#E7E8DE]">License</a></li></ul>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {slectedTokenSymbolFrom ? (
                                                            <button
                                                                id="dropdownDefaultButton" data-dropdown-toggle="dropdown"
                                                                onClick={openModal}
                                                                className=" rounded-md border border-[#34373F] inline-flex items-center bg-[#000] px-1.5 pr-[10px] py-[3px] text-sm sm:text-lg font-semibold text-[#E7E8DE] uppercase  border-0  hover:bg-black" type="button">
                                                                <img className="w-[30px] h-[30px] p-[4px] mr-1" src={fromSelectedToken ? fromSelectedToken : ethsmallnew} alt="eth" />
                                                                <p onClick={() => isWalletConnect()} className="mr-2 xl:mr-1">{slectedTokenSymbolFrom}</p><svg className="-mr-1 h-[22px] w-[25px] text-[#E7E8DE]" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd"></path></svg>
                                                            </button>
                                                        ) : (
                                                            <button id="" onClick={openModal} className="rounded-md border border-transparent inline-flex items-center bg-[#000] px-[10px] pr-[10px] py-[4px] pb-[4px] text-sm sm:text-lg font-semibold captilize   hover:bg-black" type="button">
                                                                <p onClick={() => isWalletConnect()} className="mr-2 xl:mr-1 text-[18px] text-gradient">Select Token</p><svg className="-mr-1 mt-1 h-[22px] w-[22px] text-[#c59eb8]" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd"></path></svg></button>
                                                        )}
                                                        <div id="dropdown" className="z-10 bg-black divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 hidden" data-popper-placement="bottom">
                                                            <ul className="py-2 px-2 text-[12px] text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                                                                <li>
                                                                    {/* <a href="#" className="text-[#E7E8DE] block px-4 py-2 hover:bg-gray-100 rounded-md dark:hover:bg-gray-600 dark:hover:text-[#E7E8DE]">Account settings</a> */}
                                                                    {/* {tokenData && Object.keys(tokenData.tokens).map((tokenId) => (
                                                            <div key={tokenId}>
                                                                {tokenData.tokens[tokenId].map((token: any) => (
                                                                    <div className="flex" key={token.address}>
                                                                        <img src={token.logoURI} width={25} height={25}/> 
                                                                        <span >
                                                                        {token.symbol}
                                                                        </span>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            ))} */}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>


                                                {/* <div
                                                    id="accordion-collapse-body-125"
                                                    className={activeAccordion === 'accordion-collapse-body-125' ? 'block' : 'hidden'}
                                                    aria-labelledby="accordion-collapse-heading-125">
                                                    <ul className="text-left py-2 text-[14px] text-sm text-gray-700 dark:text-gray-200 pt-0" aria-labelledby="dropdownDefaultButtonBM">
                                                    <li className='px-[16px] py-[10px] hover:bg-[#424242]'>
                                                        <a href="#" className="flex gap-3 text-white text-[16px] font-normal">
                                                       
                                                        </a> 
                                                        {tokenData && Object.keys(tokenData.tokens).map((tokenId) => (
                                                            <div key={tokenId}>
                                                                {tokenData.tokens[tokenId].map((token: any) => (
                                                                    <div className="flex" key={token.address}>
                                                                        <img src={token.logoURI} width={25} height={25}/> 
                                                                        <span >
                                                                        {token.symbol}
                                                                        </span>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            ))}
                                                        
                                                    </li>
                                                
                                                    </ul>
                                            </div> */}
                                                {fromAmountInBusd !== 0 && valueToConvert !== '' && valueToConvert !== '0' && useAccount_.status ==='connected' && (
                                                    <div className='flex justify-between items-center mt-[8px]'>
                                                        <p className='text-[12px] text-[#9b9b9b]'>{slectedTokenSymbolFrom}(~${addCommas(String(fromAmountInBusd))})</p>
                                                        <p className='text-[12px] text-[#9b9b9b] pr-0.5'>Balance:&nbsp;{String(useTokenBalance?.data?.value !== undefined ? useTokenBalance?.data?.formatted : Number(useWalletBalace?.data?.formatted).toFixed(3) ? Number(useWalletBalace?.data?.formatted).toFixed(3) : '')} {String(useTokenBalance?.data?.value !== undefined ? useTokenBalance?.data?.symbol : '')}</p>
                                                    </div>
                                                )}
                                            </div>
                                            <div className='you-pay bg-[#272727] rounded-2xl border-transparent p-4 hover:border-gray-700 mt-[3px]'>
                                                <label className="block flex text-left text-sm font-medium  text-[#9b9b9b]">You Recieve
                                                    {/* {showLoader && (
                                                    <div role="status" className='flex ml-1' >
                                                        <svg aria-hidden="true" className="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                        </svg>
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                )} */}
                                                </label>
                                                <div className="relative">
                                                    <input type="text" name="text" id="email" autoComplete='off' value={inDecimal_ && valueToConvert !== '0' ? scientificToDecimal(String( Number(inDecimal_).toFixed(10)), 10) : ''}
                                                         className={`mt-1 mr-4 block px-0 text-[22px] leading-[22px] sm:text-[32px] bg-transparent w-full rounded-md border-0 py-0.5 shadow-0 ring-1 ring-transparent ring-gray-300 ${disableInput ? 'text-transparent placeholder-transparent' : 'text-[#E7E8DE] placeholder:text-[#E7E8DE] focus:ring-transparent sm:leading-1'} ${valueToConvert === '' ? 'placeholder:text-[#1b1b1a] text-[#525151]' : ''}`}
                                                         placeholder="0" />
                                                    <div className='flex justify-between items-center mt-2'>
                                                        <div className=''>
                                                            <button onClick={() => bridgeOpen_1()} className=" text-lg text-[#9b9b9b] font-no rmal pl-[7px] pr-[9px] py-[2px] bg-[#000000] border border-transparent rounded-lg flex items-center gap-2" type="button">
                                                                {toChainId?.name && useAccount_.status === 'connected' ? (

                                                                    <p className="text-[14px] flex items-center">
                                                                        <img className="w-6 h-6 p-[4px]" src={toChainId.image ? toChainId.image : ethsmall} alt="eth" />
                                                                        {toChainId?.name ? toChainId.name : 'Ethereum'}
                                                                    </p>
                                                                ) : (
                                                                    <p className="mr-2 xl:mr-1 text-[18px] text-gradient">Select Chain</p>
                                                                )}
                                                                {/* <p className="text-[14px]">
                                                            {toChainId?.name ? toChainId.name : 'Ethereum'}
                                                        </p> */}
                                                                <svg className="-mr-1 h-5 w-5 text-[#9b9b9b]" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                                    <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd">
                                                                    </path>
                                                                </svg>
                                                            </button>
                                                            <div id="dropdown118" className="z-10 bg-black divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 hidden" data-popper-placement="bottom"><ul className="py-2 px-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton118"><li><a href="#" className="block px-4 py-2 hover:bg-[#212125] text-white rounded-md dark:hover:bg-gray-600 dark:hover:text-[#E7E8DE]">Account settings</a></li><li><a href="#" className="text-[#E7E8DE] block px-4 py-2 hover:bg-gray-100 rounded-md dark:hover:bg-gray-600 dark:hover:text-[#E7E8DE]">Support</a></li><li><a href="#" className="text-[#E7E8DE] block px-4 py-2 hover:bg-gray-100 rounded-md dark:hover:bg-gray-600 dark:hover:text-[#E7E8DE]">License</a></li></ul></div>
                                                        </div>
                                                        {slectedTokenSymbolTo ? (
                                                            <button
                                                                id="dropdownDefaultButton" data-dropdown-toggle="dropdown"
                                                                onClick={openModalTo}
                                                                className=" rounded-md border border-[#34373F] inline-flex items-center bg-[#000] px-1.5 pr-[10px] py-[3px] text-sm sm:text-lg font-semibold text-[#E7E8DE] uppercase  border-0  hover:bg-black" type="button">
                                                                <img className="w-[30px] h-[30px] p-[4px] mr-1" src={toSelectedToken ? toSelectedToken : ethsmallnew} alt="eth" />   <p className="mr-2 xl:mr-1">{slectedTokenSymbolTo}</p><svg className="-mr-1 h-[22px] w-[25px] text-[#E7E8DE]" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd"></path></svg>
                                                            </button>
                                                        ) : (
                                                            <button id="" onClick={openModalTo} className="rounded-md border border-transparent inline-flex items-center bg-[#000] px-[10px] pr-[10px] py-[4px] pb-[4px] text-sm sm:text-lg font-semibold captilize   hover:bg-black" type="button"><p className="mr-2 xl:mr-1 text-[18px] text-gradient">Select Token</p><svg className="-mr-1 mt-1 h-[22px] w-[22px] text-[#c59eb8]" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd"></path></svg></button>
                                                        )}
                                                        {/* <div id="dropdown" className="z-10 bg-black divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 hidden" data-popper-placement="bottom"><ul className="py-2 px-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton"><li><a href="#" className="block px-4 py-2 hover:bg-[#212125] rounded-md dark:hover:bg-gray-600 dark:hover:text-[#E7E8DE]">Account settings</a></li><li><a href="#" className="text-[#E7E8DE] block px-4 py-2 hover:bg-gray-100 rounded-md dark:hover:bg-gray-600 dark:hover:text-[#E7E8DE]">Support</a></li><li><a href="#" className="text-[#E7E8DE] block px-4 py-2 hover:bg-gray-100 rounded-md dark:hover:bg-gray-600 dark:hover:text-[#E7E8DE]">License</a></li></ul></div> */}
                                                    </div>
                                                    {toAmountInUsd && toAmountInUsd !== '' && valueToConvert !== '' && valueToConvert !== '0' && useAccount_.status ==='connected' && (
                                                        <div className='flex justify-between items-center mt-[8px]'>
                                                            <p className='text-[12px] text-[#9b9b9b]'>{slectedTokenSymbolTo}(~${addCommas(String(toAmountInUsd))})</p>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div>
                                                <button disabled={isButtonDisable  && useAccount_.status === 'connected'} style={{
                                                    display: "flex",
                                                    justifyContent: "center"
                                                }} className=' w-full rounded-xl p-4 mt-1 text-[#b1b2ff] font-bold text-sm sm:text-lg bg-[#424242]'>
                                                    {useAccount_.status === 'disconnected' ? (
                                                <span className='text-gradient' onClick={() => connectWalletClicked()}>Connect Wallet</span>
                                            ) : (
                                                    <span onClick={handleButtonClick} className="mr-2 xl:mr-1 flex text-[18px] text-gradient  cursor-pointer ">
                                                        {swapText}
                                                        {/* {bigNumber(lifiResp?.value?._hex) == 'NaN' && buttonClicked && useTokenBalance?.data?.formatted !== '0' ?
                                                        <div role="status" className='flex ml-1' >
                                                            <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                            </svg>
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                        : ''} */}
                                                    </span>

                                                     )} 
                                                </button></div>
                                            {toAmountInUsd && toAmountInUsd !== '' && valueToConvert !== '' && valueToConvert !== '0' && useAccount_.status ==='connected' && (
                                                <div className='mt-1'>
                                                    <h2 >
                                                        <div className="acc-col-bg py-[12px] px-[16px] bg-[#1d1d20] border  border-[#34373F] rounded-2xl focus:ring-0  dark:focus:ring-transparent dark:border-gray-700 dark:text-[#9b9b9b] hover:bg-[#1d1d22] dark:hover:bg-gray-800 gap-3">
                                                            <div className='flex items-center justify-between w-full  font-medium rtl:text-right text-[#9b9b9b] '>
                                                                <span className='text-[11px] md:text-[14px] text-left'>
                                                                    {/* <h1 style={cutomColor}>
                                                { getReason}
                                            </h1> */}
                                                                    {valueToConvert}&nbsp;{slectedTokenSymbolFrom} = {scientificToDecimal(String(inDecimal_ ? Number(inDecimal_).toFixed(4) : 0), 4) !== 'NaN' ? scientificToDecimal(String(inDecimal_ ? inDecimal_ : 0), 4) : ''} {slectedTokenSymbolTo} <span className='text-[11px] md:text-[12px] text-[#9b9b9b]'>(${toAmountInUsd})</span></span>
                                                                <div onClick={() => accordian(openAccordian)} className='cursor-pointer flex items-center gap-2'> <div className='flex gap-2 items-center'><img className='h-[15px] brightness-50' src={fuel} /><p className='text-[11px] md:text-[12px] font-medium text-[#9b9b9b]'>${gasCost}</p>
                                                                    <img  src={ardown} data-accordion-icon className="cursor-pointer w-3 h-3 brightness-50 shrink-0" aria-hidden="true" /></div> </div>
                                                            </div>
                                                            <div className='flex justify-between mt-2'>
                                                            <div className="flex items-center gap-1 md:gap-1.5 w-full flex flex-wrap " >
                                                                <p style={{flex: '0 0 13%'}} className=" flex-nonew-13-percent text-[12px] md:text-[14px] text-[#9b9b9b] font-medium">
                                                                    {" "}
                                                                    Best Route:{" "}
                                                                </p>{" "}
                                                                {/* <p className="text-[12px] md:text-[14px] text-[#9383ff] font-medium flex items-center gap-1">
                                                                    {" "}
                                                                    <img
                                                                    className="rounded-full h-[17px]"
                                                                    src={toolImg}
                                                                    />{" "}
                                                                    {toolName}{" "}
                                                            </p> */}
                                                            
                                                                <div style={{flex: '0 0 66.3%'}}>
                                                                <button className='flex-none  bg-[#30324D] font-medium flex items-center gap-1.5 rounded-lg px-2 py-1.5'><img className='h-[16px]' src={toolImg ? toolImg : toolImg} /><p className='text-[#898DF7] text-[12px]'>{toolName ? toolName : toolName}</p></button>
                                                                </div>
                                                                                                
                                                                <button   className=' text-left bg-[#30324D] text-[#898DF7] text-[12px]  rounded-full px-3 pb-1.5 py-1 font-bold'>
                                                                    { 'Recommended'  }
                                                                </button>
                                                                </div>
                                                                {/* <div className='flex items-center gap-1 md:gap-1.5'>
                                                                <p className='text-[12px] md:text-[14px] text-[#9b9b9b] font-medium'> Price Imapct:</p>
                                                                <p className='text-[12px] md:text-[14px] text-[#7ba0ff] font-medium text-[#7ba0ff]'> ~0.305% </p>
                                                            </div> */}
                                                            </div>
                                                        </div>
                                                    </h2>
                                                    {openAccordian &&(
                                                        <div  className=" mt-[-25px]" >
                                                            <div className="p-[16px] pb-[5px] pt-[25px] border border-t-0  border-[#34373F] rounded-b-2xl dark:border-gray-700 bg-[#1d1d20]">

                                                                <div className='py-2 flex justify-between border-t border-t-[#34373F]'>
                                                                    <p className=' text-[12px] md:text-[14px] text-[#9b9b9b] font-medium'>
                                                                        Max. slippage
                                                                    </p>
                                                                    <div className="flex border border-[#ffffff12] w-[30%] rounded-[10px] pr-[13px] pl-[5px] flex justify-between items-center">
                                                                        <div>
                                                                            <span className='gradient_btn block bg-[#ffffff12] text-[#9b9b9b] text-[12px] px-[15px] pt-[2px] pb-[3px] rounded-md cursor-pointer' onClick={() => Slippage(0.5)}>auto</span>
                                                                        </div>
                                                                        <div className='flex items-center'>  <input  onChange={(e) => Slippage(e.target.value)} value={customSlippage} type="text" min="1" max="100" className="text-right w-full py-1 px-2 placeholder:text-[#ABACAC] text-[#E7E8DE] font-normal text-[14px] bg-transparent border-none focus:border-transparent focus:ring-0 focus:outline-none" placeholder="0.5" />
                                                                            <p className="text-[13px] text-[#E7E8DE] font-semibold">%</p></div>
                                                                    </div>
                                                                </div>
                                                                <div className='pb-2 flex justify-between'>
                                                                    <p className='text-[12px] md:text-[14px] text-[#9b9b9b] font-medium'>
                                                                        Fee (0.15%)
                                                                    </p>
                                                                    <p className='text-[14px] text-[#E7E8DE] font-medium'>
                                                                    ${Number(((toAmountInUsd ? toAmountInUsd : 0) as any * 0.15) / 100).toFixed(3)}
                                                                    </p>
                                                                </div>
                                                                <div className='py-2 flex justify-between'>
                                                                    <p className='text-[12px] md:text-[14px] text-[#9b9b9b] font-medium'>
                                                                        Network cost
                                                                    </p>
                                                                    <p className='text-[14px] text-[#E7E8DE] font-medium flex items-center gap-2' >
                                                                        <img src={networkName_1.iconUrl} />

                                                                        ${gasCost}
                                                                    </p>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    )}

                                                </div>
                                            )}

                                        </div>
                                    </div>
                                </div>

                                {fromChainId?.id !== 0 && toChainId?.id !== 0 && fromWei !== 0 && toSelectedTokenAddress !== '' && fromTokenAmountAddress_ !== '' && (

                                    <div className='border  border-[#34373F] bg-[#2222268a] w-full lg:w-5/12 rounded-2xl p-4 sm:p-5'>
                                        <div className='overflow-y-scroll  ' style={{ maxHeight: "80vh" }}>
                                            <div className='choose head   pb-5 flex items-center justify-between'>
                                                <div className='flex gap-4 '><p className='text-left text-[#E7E8DE] text-md font-semibold'>You Get</p></div>
                                            </div>
                                            {routesResultResponse && routesResultResponse?.routes.map((data: any, index: any) => (
                                                <div key={index} onClick={() => getIndex(index, data?.steps[0]?.toolDetails?.logoURI, data?.steps[0]?.toolDetails?.name)} className='mb-[10px] hover:bg-blue-500 cursor-pointer text-white text-left rounded-[19px] border border-[#34373F] p-4 bg-[#272727]'>
                                                    {data?.tags.length < 1 ? (
                                                        // <button className='mb-[10px] text-left bg-[#30324D] text-[#898DF7] text-[12px]  rounded-full px-3 pb-1.5 py-1 font-bold'>
                                                        // </button>
                                                        <span></span>
                                                    ) : (
                                                        <button className='mb-[10px] text-left bg-[#30324D] text-[#898DF7] text-[12px]  rounded-full px-3 pb-1.5 py-1 font-bold'>
                                                            {data?.tags[0] === 'FASTEST' ? 'Fast' : data?.tags[0] === 'RECOMMENDED' ? 'Recommended' : data?.tags[0] === 'CHEAPEST' ? 'Cheap' : 'Safest'}
                                                        </button>
                                                    )

                                                    }
                                                    <div className=''>
                                                        <div className='flex justify-between items-start'>
                                                            <div className='flex gap-3 '>
                                                                <div className='relative'>  <img className='h-[50px] w-[50px]' src={data?.toToken?.logoURI} />
                                                                    <img className='absolute right-[-2px] bottom-[-4px] border-[2.5px] border-[#434343] rounded-full h-[23px]' src={toChainId.image} />
                                                                </div>
                                                                <div>
                                                                    <p className='text-[18px] text-semibold text-[#E7E8DE]'>
                                                                        {scientificToDecimal(String(inDecimal(data?.toAmount)), 4)}
                                                                    </p>
                                                                    <div className='flex items-center gap-3'><p className='text-[#BDBEBE] text-[13px] font-medium'>${data?.toAmountUSD}</p></div>
                                                                </div>
                                                            </div>
                                                            <div className='flex items-end justify-end gap-2'>
                                                                <div>
                                                                    <button className='bg-[#30324D] font-medium flex items-center gap-1.5 rounded-lg px-2 py-1.5'><img className='h-[16px]' src={data?.steps[0]?.toolDetails?.logoURI} /><p className='text-[#898DF7] text-[12px]'>{data?.steps[0]?.toolDetails?.name}</p></button>
                                                                </div>
                                                                <div className='bg-[#212125] p-1 rounded-full'>
                                                                    <svg className=" h-5 w-5.5 text-[#E7E8DE]" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd"></path></svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='flex justify-between mt-[20px] px-[5px]'>
                                                        <div className='flex gap-2'><img className='h-[18px] contrast-50' src={fuel} /><p className='text-[12px] font-medium text-[#ffffffc2]'>${data?.gasCostUSD}</p></div>
                                                        <div className='flex gap-2'><img className='h-[18px] contrast-50' src={coin} /><p className='text-[12px] font-medium text-[#ffffffc2]'>$0.00</p></div>
                                                        <div className='flex gap-2'><img className='h-[18px] contrast-50' src={clock} /><p className='text-[12px] font-medium text-[#ffffffc2]'>7m</p></div>
                                                        <div className='flex gap-2'><img className='h-[18px] contrast-50' src={layers} /><p className='text-[12px] font-medium text-[#ffffffc2]'>1</p></div>
                                                    </div>
                                                </div>
                                            ))}
                                            {/* <div className='mb-[10px] text-left rounded-[19px] border border-[#34373F] p-4 bg-[#272727]'>

                                        <div className=''>
                                            <div className='flex justify-between items-start'>
                                                <div className='flex gap-3'>
                                                    <div className='relative'>  <img className='h-[50px] w-[50px]' src={fswap} />
                                                        <img className='absolute right-[-2px] bottom-[-4px] border-[2.5px] border-[#434343] rounded-full h-[23px]' src={fswapsmall} />
                                                    </div>
                                                    <div>
                                                        <p className='text-[18px] text-semibold text-[#E7E8DE]'>
                                                            9,1379
                                                        </p>
                                                        <div className='flex items-center gap-3'><p className='text-[#BDBEBE] text-[13px] font-medium'>$2,372.76</p></div>
                                                    </div>
                                                </div>
                                                <div className='flex items-end justify-end gap-2'>

                                                    <div>
                                                        <button className='bg-[#30324D] font-medium flex items-center gap-1.5 rounded-lg px-2 py-1.5'><img className='h-[16px]' src={fswap1} /><p className='text-[#898DF7] text-[12px]'>Context</p></button>
                                                    </div>
                                                    <div className='bg-[#212125] p-1 rounded-full'>
                                                        <svg className=" h-5 w-5.5 text-[#E7E8DE]" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd"></path></svg>
                                                    </div></div>
                                            </div>

                                        </div>
                                        <div className='flex justify-between mt-[20px] px-[5px]'>
                                            <div className='flex gap-2'><img className='h-[18px] contrast-50' src={fuel} /><p className='text-[12px] font-medium text-[#ffffffc2]'>$46.44</p></div>
                                            <div className='flex gap-2'><img className='h-[18px] contrast-50' src={coin} /><p className='text-[12px] font-medium text-[#ffffffc2]'>$0.00</p></div>
                                            <div className='flex gap-2'><img className='h-[18px] contrast-50' src={clock} /><p className='text-[12px] font-medium text-[#ffffffc2]'>7m</p></div>
                                            <div className='flex gap-2'><img className='h-[18px] contrast-50' src={layers} /><p className='text-[12px] font-medium text-[#ffffffc2]'>1</p></div>
                                        </div>
                                    </div>


                                    <div className='mb-[10px] text-left rounded-[19px] border border-[#34373F] p-4 bg-[#272727]'>

                                        <div className=''>
                                            <div className='flex justify-between items-center'>
                                                <div className='flex gap-3'>
                                                    <div className='relative'>  <img className='h-[50px] w-[50px]' src={fswap} />
                                                        <img className='absolute right-[-2px] bottom-[-4px] border-[2.5px] border-[#434343] rounded-full h-[23px]' src={fswapsmall} />
                                                    </div>
                                                    <div>
                                                        <p className='text-[18px] text-semibold text-[#E7E8DE]'>
                                                            9,1379
                                                        </p>
                                                        <div className='flex items-center gap-3'><p className='text-[#BDBEBE] text-[13px] font-medium'>$2,372.76</p> </div>
                                                    </div>
                                                </div>
                                                <div className='flex items-end justify-end gap-2'>

                                                    <div>
                                                        <button className='bg-[#30324D] font-medium flex items-center gap-1.5 rounded-lg px-2 py-1.5'><img className='h-[16px]' src={fswap1} /><p className='text-[#898DF7] text-[12px]'>Context</p></button>
                                                    </div>
                                                    <div className='bg-[#212125] p-1 rounded-full'>
                                                        <svg className="rotate-180 h-5 w-5.5 text-[#E7E8DE]" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd"></path></svg>
                                                    </div></div>
                                            </div>
                                            <div className='pt-[20px] pl-[30px]'>
                                                <div className=' flex items-center gap-2'>
                                                    <div className='relative inline-block'>
                                                        <img className='h-[49px] w-[47px] relative' src={fswap1} />
                                                        <img className='absolute right-0 bottom-[0px]' src={fswapsmall} />

                                                    </div>
                                                    <div>
                                                        <button className='bg-[#30324D] font-medium flex items-center gap-2 rounded-lg px-3 py-1.5'><p className='text-[#898DF7] text-[16px]'>Hyphen via LI.FI</p></button>

                                                    </div>
                                                </div>
                                                <div className='coin-left-b purple_line relative'>
                                                    <ul className='pl-[50px] pt-[30px]'>
                                                        <li className='flex items-center gap-4 mb-[18px]'>
                                                            <img className='h-[22px] w-[22px]' src={fswapsmall} /><p className='text-[15px] text-[#E7E8DE]'>Swap on Ethereum via BSC</p>
                                                        </li>
                                                        <li className='flex items-center gap-4 mb-[18px]'>
                                                            <img className='h-[22px] w-[22px]' src={fswap1} /><p className='text-[15px] text-[#E7E8DE]'>Bridge from etherium via BSC via connext</p>
                                                        </li>
                                                        <li className='flex items-center gap-4 mb-[18px]'>
                                                            <img className='h-[22px] w-[22px]' src={fswapsmall} /><p className='text-[15px] text-[#E7E8DE]'>Swip or BSC 1inch</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='flex justify-between mt-[20px] px-[5px]'>
                                            <div className='flex gap-2'><img className='h-[18px] contrast-50' src={fuel} /><p className='text-[12px] font-medium text-[#ffffffc2]'>$46.44</p></div>
                                            <div className='flex gap-2'><img className='h-[18px] contrast-50' src={coin} /><p className='text-[12px] font-medium text-[#ffffffc2]'>$0.00</p></div>
                                            <div className='flex gap-2'><img className='h-[18px] contrast-50' src={clock} /><p className='text-[12px] font-medium text-[#ffffffc2]'>7m</p></div>
                                            <div className='flex gap-2'><img className='h-[18px] contrast-50' src={layers} /><p className='text-[12px] font-medium text-[#ffffffc2]'>1</p></div>
                                        </div>
                                    </div> */}

                                        </div>
                                    </div>
                                )}
                            </div>

                            {/* <div className="App">
                         <Footerlinksfixed name="John" />
                        </div> */}



                        </div>
                    </div>
                )}

                {isBridgesOpen && useAccount_?.status === 'connected' && (
                    <div style={{ top: '6rem', position: "absolute", width: "30rem", left: "24%" }} className='w-full z-999999999 absolute  mb-[-71rem] ml-[15rem] lg:w-5/12 py-[20px] px-[20px] rounded-2xl bg-[#131313] border border-[#34373F]'>
                        <div className='flex items-center justify-between gap-2 '>

                            <p className='text-[#d3d3d3] text-[#d3d3d3] text-[24px] font-semibold'>Enabled Chains</p>
                            <div className='' onClick={bridgeClosed} ><img src={menub} /></div>
                        </div>
                        <div className='relative my-[20px]'>
                            <img src={search} className='h-[16px] w-[16px] absolute left-[15px] top-[18px] z-30 brightness-50' />
                            <input

                                className='pl-[40px] font-[Inter]  input_token w-full h-[50px] text-[#4bbcf9] bg-[#1A1B1E] text-md font-light placeholder:text-[#8e8e91] px-[12px] py-[8px] border border-[#343434] rounded-xl max-w-[460px]'
                                placeholder='Search...'

                            />
                        </div>
                        <div className='mic_list h-[350px] overflow-hidden overflow-y-auto pr-[10px] scroll-h'>
                            <div className='flex w-100 flex-col justify-between ml-[15px] pb-[15px]'>
                                {chains && chains?.map((chain: any, index: any) => (
                                    <div key={index} onClick={() => { getFromChainId(chain.id, chain.iconUrl, chain.name); bridgeClosed(); switchNetwork?.(chain.id) }} className='flex items-center justify-start pb-[15px] gap-[20px] ' >
                                        <a href="#" className=""><img className="h-5.5 w-5 " src={chain?.iconUrl} /></a>
                                        <div className='text-left'>
                                            <p className='t ext-[18px] md:text-[20px] leading-[32px] font-semibold text-[#E7E8DE] text-left flex gap-2 items-center'>{chain?.name}</p>
                                            <p className='text-[15px]  text-[#B1B1B1]'>Corporate structure</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                )}

                {isBridgesOpen_1 && useAccount_?.status === 'connected' && (
                    <div style={{ top: '6rem', position: "absolute", width: "30rem", left: "24%" }} className='w-full z-999999999 absolute  mb-[-71rem] ml-[15rem] lg:w-5/12 py-[20px] px-[20px] rounded-2xl bg-[#131313] border border-[#34373F]'>
                        <div className='flex items-center justify-between gap-2 '>

                            <p className='text-[#d3d3d3] text-[#d3d3d3] text-[24px] font-semibold'>Enabled Chains</p>
                            <div className='' onClick={bridgeClosed_1}><img src={menub} /></div>
                        </div>
                        <div className='relative my-[20px]'>
                            <img src={search} className='h-[16px] w-[16px] absolute left-[15px] top-[18px] z-30 brightness-50' />
                            <input

                                className='pl-[40px] font-[Inter]  input_token w-full h-[50px] text-[#4bbcf9] bg-[#1A1B1E] text-md font-light placeholder:text-[#8e8e91] px-[12px] py-[8px] border border-[#343434] rounded-xl max-w-[460px]'
                                placeholder='Search...'

                            />
                        </div>
                        <div className='mic_list h-[350px] overflow-hidden overflow-y-auto pr-[10px] scroll-h'>
                            <div className='flex w-100 flex-col justify-between ml-[15px] pb-[15px]'>
                                {chains && chains?.map((chain: any, index: any) => (
                                    <div key={index} onClick={() => { getToChainId(chain.id, chain.iconUrl, chain.name); bridgeClosed_1() }} className='flex items-center justify-start pb-[15px] gap-[20px] ' >
                                        <a href="#" className=""><img className="h-5.5 w-5 " src={chain?.iconUrl} /></a>
                                        <div className='text-left'>
                                            <p className='t ext-[18px] md:text-[20px] leading-[32px] font-semibold text-[#E7E8DE] text-left flex gap-2 items-center'>{chain?.name}</p>
                                            <p className='text-[15px]  text-[#B1B1B1]'>Corporate structure</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                )}

                {isModalOpen && useAccount_?.status === 'connected' && (


                    <div style={{
                        zIndex: 99,
                        width: "85%",
                        top: "40rem",
                        left: "6%",
                    }} className='option absolute box mt-[-28.5rem] mb-[2.5rem]  flex'>
                        <div className='border border-[#34373F] bg-[#000000e0]  rounded-2xl  md:w-3/12 w-3/12 mx-auto p-2 sm:p-5 '>
                            <div className="flex  items-center justify-between gap-2 mb-[20px]"><p className="text-[#d3d3d3] text-[16px] font-semibold">Select a token</p>
                                <div className=''><svg xmlns="http://www.w3.org/2000/svg" onClick={closeModal} width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="text-white components__CloseIcon-sc-1717d76c-0 hHZYlF"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></div></div>
                            <div className='relative'>
                                <img src={search} className='h-[16px] w-[16px] absolute left-[15px] top-[18px] z-30 brightness-50' />
                                <input
                                    className='pl-[40px] font-[Lausanne]  input_token w-full h-[50px] text-white bg-[#1A1B1E] text-[14px] font-light placeholder:text-[#8e8e91] px-[12px] py-[8px] border border-[#343434] rounded-xl max-w-[460px]'
                                    placeholder='Search by name or paste address'
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                />
                            </div>

                            <div className='mic_list mt-[20px] h-[270px] overflow-hidden overflow-y-auto pr-[15px] scroll-h '>
                                <div className='flex items-start justify-between pb-[15px]'>
                                    {
                                        searchTerm ? (

                                            <span>
                                                {renderTokensFrom()}
                                            </span>
                                        ) : (
                                            <span>
                                                {fromTokenData && Object.keys(fromTokenData.tokens).map((tokenId) => (
                                                    <div key={tokenId} className='flex flex-col justify-start items-start gap-[20px] '>
                                                        {fromTokenData.tokens[tokenId].slice(0, 100).map((token: any) => (
                                                            <div key={token.address} className='flex' onClick={async () => {
                                                                await getTokenAddressFrom(token.logoURI, token.address, token.symbol);
                                                                closeModal();
                                                            }}>
                                                                <img className='w-[40px] h-[40px] rounded-full' src={token.logoURI} />
                                                                <div className='text-left ml-2'>
                                                                    <p className='text-[16px] leading-[22px] font-semibold text-[#ffffff] text-left flex gap-2 items-center uppercase'>{token.symbol}</p>
                                                                    <p className='text-[12px]  text-[#B1B1B1]'>{token.name}</p>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))}
                                            </span>
                                        )}


                                </div>
                            </div>
                        </div>

                    </div>
                )}

                {isModalOpenTo && (

                    <div style={{
                        zIndex: 99,
                        width: "85%",
                        top: "40rem",
                        left: "6%",
                    }} className='option box mt-[-28.5rem] mb-[2.5rem] relative  flex'>
                        <div className='border border-[#34373F] bg-[#000000e0]  rounded-2xl  md:w-3/12 w-3/12 mx-auto p-2 sm:p-5 '>
                            <div className="flex  items-center justify-between gap-2 mb-[20px]"><p className="text-[#d3d3d3] text-[16px] font-semibold">Select a token</p>
                                <div className=''><svg xmlns="http://www.w3.org/2000/svg" onClick={closeModalTo} width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="text-white components__CloseIcon-sc-1717d76c-0 hHZYlF"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></div></div>
                            <div className='relative'>
                                <img src={search} className='h-[16px] w-[16px] absolute left-[15px] top-[18px] z-30 brightness-50' />

                                <input
                                    className='pl-[40px] font-[Lausanne]  input_token w-full h-[50px] text-white bg-[#1A1B1E] text-[14px] font-light placeholder:text-[#8e8e91] px-[12px] py-[8px] border border-[#343434] rounded-xl max-w-[460px]'
                                    placeholder='Search by name or paste address'
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                />
                            </div>
                            <div className='max-h-[300px] overflow-scroll' >
                                {/* {
                                searchTerm ? (
                              
                              <span>
                                {renderTokensTo()}
                              </span>
                                ):(
                              <span>

                                {tokenData && Object.keys(tokenData.tokens).map((tokenId) => (
                                <div key={tokenId} className='max-h-[300px] overflow-scroll'>
                                    {tokenData.tokens[tokenId].slice(0,10).map((token: any) => (
                                        <div className="flex mb-1.5" key={token.address} onClick={() => getTokenAddressTo(token.logoURI, token.address, token.symbol)}>
                                            <img src={token.logoURI} width={25} height={25} />
                                            <span >
                                                {token.symbol}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            ))}
                              </span>
                                    )} */}

                                {
                                    searchTerm ? (

                                        <span>
                                            {renderTokensTo()}
                                        </span>
                                    ) : (
                                        <span>
                                            {toTokenData && Object.keys(toTokenData.tokens).map((tokenId) => (
                                                <div key={tokenId} className='flex flex-col justify-start items-start gap-[20px] '>
                                                    {toTokenData.tokens[tokenId].slice(0, 100).map((token: any) => (
                                                        <div key={token.address} className='flex' onClick={async () => {
                                                            await getTokenAddressTo(token.logoURI, token.address, token.symbol);
                                                            closeModalTo();
                                                        }}>
                                                            <img className='w-[40px] h-[40px] rounded-full' src={token.logoURI} />
                                                            <div className='text-left ml-2'>
                                                                <p className='text-[16px] leading-[22px] font-semibold text-[#ffffff] text-left flex gap-2 items-center uppercase'>{token.symbol}</p>
                                                                <p className='text-[12px]  text-[#B1B1B1]'>{token.name}</p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ))}
                                        </span>
                                    )}
                            </div>



                        </div>
                    </div>
                )}

                {openSetting && !isBridgeOpen && !isExchageOpen && (
                    <div className='pt-[123px]'>
                        <div className='mx-auto px-[20px] sm:px-[20px] lg:px-[30px] xl:w-10/12 text-center'>
                            <div className='option box my-10 mt-[0px]'>
                                <div className='flex md:flex-row flex-col items-start gap-4 justify-center'>
                                    <div className='text-left font[inter] border border-[#34373F] bg-[#131313]  rounded-2xl md:w-8/12  xl:w-5/12 w-full p-2 px-[10px] pb-[20px] '>
                                        <div className='flex items-center justify-between mb-[20px]'>
                                            <a onClick={() => toggleSettingModel(openSetting)} ><svg className='h-[34px] w-[34px] p-2 rounded-full hover:bg-[#383838] shadow-md cursor-pointer' xmlns="http://www.w3.org/2000/svg" version="1.1" width="512" height="512" x="0" y="0" viewBox="0 0 24 24" ><g><path d="M22 11H4.414l5.293-5.293a1 1 0 1 0-1.414-1.414l-7 7a1 1 0 0 0 0 1.414l7 7a1 1 0 0 0 1.414-1.414L4.414 13H22a1 1 0 0 0 0-2z" fill="#ffffff" opacity="1" data-original="#000000"></path></g></svg></a>
                                            <p className='px-3 text-[27px] text-[#E7E8DE] leading-0 '>Settings</p>
                                            <div className='w-[34px]'></div>

                                        </div>
                                        <div className='px-3 flex md:flex-row flex-col gap-2 justify-between'>
                                            <div onClick={() => setGasPrice(false)} className='md:w-[33%] border border-[#383838] rounded-[14px] bg-[#1C1E1F] px-[15px] py-[12px] pb-[15px]'>
                                                <p className='text-[13px] text-[#E7E8DE] font-semibold leading-[32px]'>Route Priority</p>

                                                <button id="dropdownDefaultButton1" onClick={() => showRouteOption(route)} data-dropdown-toggle="dropdown1" className="flex justify-between w-full text-[#ABACAC] bg-transparent hover:bg-transparent focus:none focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-md px-0 py-2.5 pb-0 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">{routeType}
                                                    <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
                                                    </svg>
                                                </button>

                                                {route && (
                                                    <div style={{ left: "34.5%", width: "9.5%" }} id="dropdown1" className="absolute border border-[#383838] z-10  bg-[#1C1E1F] divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                                                        <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton1">
                                                            <li>
                                                                <a href="#" className="text-[#E7E8DE] block px-4 py-2" onClick={async () => { getRouteType('Recommended'); setRoute(false) }}>Recommended</a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="text-[#E7E8DE] block px-4 py-2" onClick={async () => { getRouteType('Fast'); setRoute(false) }}>Fast</a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="text-[#E7E8DE] block px-4 py-2" onClick={async () => { getRouteType('Cheap'); setRoute(false) }}>Cheap</a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="text-[#E7E8DE] block px-4 py-2" onClick={async () => { getRouteType('Safe'); setRoute(false) }}>Safe</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                            <div className='md:w-[33%] border border-[#383838] rounded-[14px] bg-[#1C1E1F] px-[15px] py-[12px] pb-[15px]'>
                                                <p className='text-[13px] text-[#E7E8DE] font-semibold leading-[32px]'>Slippage</p>
                                                <div className='flex justify-between items-center'>
                                                    <input onChange={(e) => Slippage(e.target.value)} value={customSlippage} type='text' min="1" max="100" className='pl-0 w-full placeholder:text-[#ABACAC] text-[#E7E8DE] font-semibold text-[16px] bg-transparent border-none focus:border-transparent focus:ring-0 focus:outline-none' placeholder='0.5' />
                                                    <p className='text-[16px] text-[#ABACAC] font-semibold leading-[32px]'>%</p>
                                                </div>

                                            </div>
                                            <div onClick={() => setRoute(false)} className='md:w-[33%] border border-[#383838] rounded-[14px] bg-[#1C1E1F] px-[15px] py-[12px] pb-[15px]'>
                                                <p className='text-[13px] text-[#E7E8DE] font-semibold leading-[32px]'>Gas Price</p>
                                                <button onClick={() => showGasOption(gas)} id="dropdownDefaultButton12" data-dropdown-toggle="dropdown12" className="flex justify-between w-full text-[#ABACAC] bg-transparent hover:bg-transparent focus:none focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-md px-0 py-2.5 pb-0 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">{gasType}
                                                    <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
                                                    </svg>
                                                </button>

                                                {gas && (
                                                    <div style={{ width: "9.5%", left: "56%" }} id="dropdown12" className="absolute border border-[#383838] z-10  bg-[#1C1E1F] divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                                                        <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton1">
                                                            <li>
                                                                <a href="#" className="text-[#E7E8DE] block px-4 py-2" onClick={async () => { getGasType('Slow'); setGasPrice(false) }}>Slow</a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="text-[#E7E8DE] block px-4 py-2" onClick={async () => { getGasType('Normal'); setGasPrice(false) }}>Normal</a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="text-[#E7E8DE] block px-4 py-2" onClick={async () => { getGasType('Fast'); setGasPrice(false) }}>Fast</a>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                )}
                                            </div>






                                        </div>

                                        <div className='px-3 pt-[20px] pb-[10px] flex justify-between items-center'>
                                        <p className='text-[18px] text-[#E7E8DE] font-semibold leading-[32px]'>Show Destination wallet</p>

                                        <label className="relative flex items-center  justify-end cursor-pointer">
                                            <input type="checkbox" value="" className="sr-only peer" checked />
                                            <div className="back-gradient w-11 h-6 rounded-full peer peer-focus:ring-4 peer-focus:[#9383ff] dark:peer-focus:[#9383ff] dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:[#9383ff]"></div>
                                        </label>

                                    </div>
                                        <div onClick={() => openBridgesModel(isBridgeOpen)} className='py-[10px] flex justify-between items-center px-3 hover:bg-[#27272a] rounded-lg'>
                                            <p className='text-[18px] text-[#E7E8DE] font-semibold leading-[32px]'>Enabled bridges</p>
                                            <button className='text-[#E7E8DE] flex items-center gap-2 font-semibold'>14/14 <img src={righta} />
                                            </button>
                                        </div>
                                        <div onClick={() => openExchangesModel(isExchageOpen)} className='py-[10px] flex justify-between items-center px-3 hover:bg-[#27272a] rounded-lg'>
                                            <p className='text-[18px] text-[#E7E8DE] font-semibold leading-[32px]'>Enabled exchanges</p>
                                            <button className='text-[#E7E8DE] flex items-center gap-2 font-semibold'>35/35 <img src={righta} />
                                            </button>
                                        </div>

                                        <div className='' >
                                            <button disabled={isButtonDisable} className='w-full gradient_btn  rounded-md font-bold mt-[20px] p-4 bg-[#9383ff] text-black'>
                                                <span onClick={() => { toggleSettingModel(openSetting); saveSetting() }} className="text-gradient cursor-pointer">
                                                    Submit
                                                </span>
                                            </button>
                                         
                                        </div>
                                        <div className='px-3'>
                                            
                                    </div>

                                    </div>
                                </div>
                            </div>
                            {
                                /*
                                <div className="App">
                                    <Footerlinksnotfixed name="John" />
    
                                </div> */
                            }

                        </div>
                    </div>
                )}

                <div className='flex md:flex-row flex-col gap-4 justify-center'>
                    {isBridgeOpen && (
                        <div className='w-full lg:w-5/12 relative top-20  mt-[15px] py-[20px] px-[20px] rounded-2xl bg-[#2222268a]   border border-[#34373F] '>
                            <div className='flex items-center justify-between gap-2'>

                                <p className='text-[#d3d3d3] text-[24px] font-semibold'>Enabled exchanges</p>
                                <div className='' onClick={() => { setSetting(true); setBridgeOpen(false) }}><img src={menub} /></div>
                            </div>
                            <div className='relative my-[20px]'>
                                <img src={search} className='h-[16px] w-[16px] absolute left-[15px] top-[18px] z-30 brightness-50' />
                                <input

                                    className='pl-[40px] font-[Inter]  input_token w-full h-[50px] text-[#4bbcf9] bg-[#1A1B1E] text-md font-light placeholder:text-[#8e8e91] px-[12px] py-[8px] border border-[#343434] rounded-xl max-w-[460px]'
                                    placeholder='Search...'

                                />
                            </div>
                            <div className='mic_list h-[350px] overflow-hidden overflow-y-auto pr-[10px] scroll-h'>
                                {bridges && bridges?.map((data: any, index: any) => (
                                    <div className='flex w-100  justify-between ml-[15px] pb-[15px]'>
                                        <div key={index} className='flex justify-start gap-[20px] '>
                                            <div> <img className='w-[40px] h-[40px]' src={data?.logoURI} /> </div>
                                            <div className='text-left'>
                                                <p className='text-[18px] md:text-[20px] leading-[32px] font-semibold text-[#E7E8DE] text-left flex gap-2 items-center'>{data?.key}</p>
                                                <p className='text-[15px]  text-[#B1B1B1]'>{data?.name}</p>
                                            </div>
                                        </div>
                                        <div className='flex items-center'>
                                            <div className="flex items-center ">
                                                <input checked id="checked-checkbox" type="checkbox" value="" className=" bg-[#9383ff] w-4 h-4 text-[#9383ff] bg-[#9383ff] border-#9383ff[] rounded focus:[#9383ff] dark:focus:[#9383ff] dark:ring-offset-[#9383ff] focus:ring-0 dark:bg-[#9383ff] dark:border-[#9383ff]" />
                                                <label htmlFor="checked-checkbox" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"></label>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {isExchageOpen && (
                                              <div className='w-full lg:w-5/12 relative top-20  mt-[15px] py-[20px] px-[20px] rounded-2xl bg-[#2222268a]   border border-[#34373F] '>

                            <div className='flex items-center justify-between gap-2 '>

                                <p className='text-[#d3d3d3] text-[#d3d3d3] text-[24px] font-semibold'>Enabled bridges</p>
                                <div className='' onClick={() => { setSetting(true); setExchangeOpen(false) }}><img src={menub} /></div>
                            </div>
                            <div className='relative my-[20px]'>
                                <img src={search} className='h-[16px] w-[16px] absolute left-[15px] top-[18px] z-30 brightness-50' />
                                <input

                                    className='pl-[40px] font-[Inter]  input_token w-full h-[50px] text-[#4bbcf9] bg-[#1A1B1E] text-md font-light placeholder:text-[#8e8e91] px-[12px] py-[8px] border border-[#343434] rounded-xl max-w-[460px]'
                                    placeholder='Search...'

                                />
                            </div>
                            <div className='mic_list h-[350px] overflow-hidden overflow-y-auto pr-[10px] scroll-h'>
                                {exhanges && exhanges?.map((data: any, index: any) => (
                                    <div key={index} className='flex items-center justify-between pb-[15px]'>
                                        <div className='flex justify-start gap-[20px] '>
                                            <div> <img className='w-[40px] h-[40px]' src={data?.logoURI} /> </div>
                                            <div className='text-left'>
                                                <p className='text-[18px] md:text-[20px] leading-[32px] font-semibold text-[#E7E8DE] text-left flex gap-2 items-center'>{data?.key}</p>
                                                <p className='text-[15px]  text-[#B1B1B1]'>{data?.name}</p>
                                            </div>
                                        </div>
                                        <div className='flex items-center'>
                                            <div className="flex items-center ">
                                                <input checked id="checked-checkbox" type="checkbox" value="" className=" bg-[#9383ff] w-4 h-4 text-[#9383ff] bg-[#9383ff] border-#9383ff[] rounded focus:[#9383ff] dark:focus:[#9383ff] dark:ring-offset-[#9383ff] focus:ring-0 dark:bg-[#9383ff] dark:border-[#9383ff]" />
                                                <label htmlFor="checked-checkbox" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"></label>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>

            </div>
        </>
    )
}

export default Advancedswap;