
import { useEffect, useState ,createContext, useContext} from 'react';
import Dropfinal from '../components/dropdownfinalstep';
import Headerfull from '../components/headerfull';
import f2 from "../images/f21.png";
import Footerlinksfixed from '../components/footerlinks';
import axios from 'axios';
import { useTokenContext } from '../Provider/useContext'; 
import { Routes, useNavigate } from 'react-router-dom';
import { ROUTES } from '../routes/routes';
import { useSwitchNetwork } from 'wagmi';
let initialTokenContext = {
  tokenAddress:'',
  pairInfo:{},
    tokenInfo: {},
    marketCap: 0,
    tradingVolume: 0,
    circulatingSupply: 0,
    maxSupply: 0,
    priceHistory: [],
    credibilityInfo: '' as any,
};
  

const TokenContext = createContext(initialTokenContext) ;
    console.log("🚀 ~ TokenContext:", TokenContext)
    
export const Analyse2 = ({ children }: any) => {
  const { tokenContext, setTokenContext } = useTokenContext();
  const navigate = useNavigate()
  const [pairInfo_, pairInfo] = useState();
    const [loaderController, setController] = useState(false);
  // const [MyProvider, setTokenContext ] = useState() as any;
    // console.log("🚀 ~ Analyse2 ~ actual_myProvider:", tokenContext?.address)
    // const [contextValues, setContextValues] = useState(initialTokenContext);
    const [tokenInfo, setTokenInfo] = useState({});
    console.log("🚀 ~ Analyse2 ~ tokenInfo:", tokenInfo)
    const [marketCap, setMarketCap] = useState(0);
    console.log("🚀 ~ Analyse2 ~ marketCap:", marketCap)
    const [tradingVolume, setTradingVolume] = useState(0);
    console.log("🚀 ~ Analyse2 ~ tradingVolume:", tradingVolume)
    const [circulatingSupply, setCirculatingSupply] = useState(0);
    console.log("🚀 ~ Analyse2 ~ circulatingSupply:", circulatingSupply)
    const [maxSupply, setMaxSupply] = useState(0);
    console.log("🚀 ~ Analyse2 ~ maxSupply:", maxSupply)
    const [priceHistory, setPriceHistory] = useState([]);
    console.log("🚀 ~ Analyse2 ~ priceHistory:", priceHistory)
    const [credibilityInfo, setCredibilityInfo] = useState() as any;
    console.log("🚀 ~ Analyse2 ~ credibilityInfo:", credibilityInfo)
  const [inputVal, setInputValue] = useState('');
  console.log("🚀 ~ Analyse2 ~ inputVal:", inputVal)
  const { chains, switchNetwork } = useSwitchNetwork();
  console.log("🚀 ~ Home ~ switchNetwork:", chains);
    
  const [isFocused, setIsFocused] = useState(false);
  
  interface obj{
    address: '',
    image: '',
  }
  const chain: obj | any = tokenContext;
  console.log('add',chain?.address)


    const handleFocus = () => {
        setIsFocused(true);
    };
    const handleBlur = () => {
        setIsFocused(false);
    };
    
  async function getTokenInfo(tokenAddress: any, chain: any) {
    setController(true);
        try {
          // Replace 'YOUR_API_ENDPOINT' with the actual endpoint you want to call
          const tokenApiUrl = 'https://api.dextools.io/v1/token';
          const pairApiUrl = 'https://api.dextools.io/v1/pair';
      
          const apiKey = '94a9f3dac407240f00951228ceecbea6';
          
          // Fetch token information
          const tokenParams = {
            chain: chain,
            address: tokenAddress,
          };
          
          const tokenHeaders = {
            'Accept': 'application/json',
            'X-API-Key': apiKey,
          };
          
          const tokenResponse = await axios.get(tokenApiUrl, { params: tokenParams, headers: tokenHeaders });
          const tokenInfo = tokenResponse.data;

          navigate(ROUTES.ANALYSIS)
          setController(false);
          // Extract pair address from tokenInfo
          if (tokenInfo?.data?.pairs.length > 0) {
            
            const pairAddress = tokenInfo?.data?.pairs[0]?.address;
      
            // Fetch pair information
            const pairParams = {
              chain: chain,
              address: pairAddress,
            };
          
            const pairHeaders = {
              'Accept': 'application/json',
              'X-API-Key': apiKey,
            };
          
          
            const pairResponse = await axios.get(pairApiUrl, { params: pairParams, headers: pairHeaders });
            const pairInfo = pairResponse.data;
            console.log('PAIRiNFO', pairInfo.data?.metrics)
            //       // Extract additional information from the pair API response
            const marketCap = pairInfo.data?.metrics?.liquidity * pairInfo.data?.price;
            setMarketCap(marketCap);
            const tradingVolume = pairInfo.data?.metrics?.reserve * pairInfo.data?.price;
            setTradingVolume(tradingVolume);
            const circulatingSupply = pairInfo.data?.metrics?.liquidity;
            setCirculatingSupply(circulatingSupply)
            const maxSupply = tokenInfo.data?.metrics?.maxSupply;
            setMaxSupply(maxSupply)
            const priceHistory = pairInfo.data?.priceHistory;
            setPriceHistory(priceHistory);
            const credibilityInfo = checkTokenCredibility(tokenInfo, pairInfo, marketCap, tradingVolume, circulatingSupply, maxSupply, priceHistory);
            setCredibilityInfo(credibilityInfo);
            initialTokenContext = {
              tokenAddress,
              pairInfo,
              tokenInfo,
              marketCap,
              tradingVolume,
              circulatingSupply,
              maxSupply,
              priceHistory,
              credibilityInfo,}
            setTokenContext(initialTokenContext);
            // useTokenContext(contextValue)}
          }
            
          return {
            tokenAddress,
            pairInfo,
            tokenInfo,
            marketCap,
            tradingVolume,
            circulatingSupply,
            maxSupply,
            priceHistory,
            credibilityInfo
          };
        } catch (error) {
          // const obj = {
      
          //   name: address,
          //   image: imageUrl
          // }
          initialTokenContext = {
            tokenAddress,
            pairInfo,
            tokenInfo,
            marketCap,
            tradingVolume,
            circulatingSupply,
            maxSupply,
            priceHistory,
            credibilityInfo,}
          setTokenContext(initialTokenContext);
          console.error('fetchingError', error);
          // setTokenContext(obj);

          navigate(ROUTES.ANALYSEERROR)
          // throw new Error('Error fetching token information');
        } 
        // catch (error) {
        //     console.log('error',error)
        // }
  }
  


      
      function checkTokenCredibility(tokenInfo:any, pairInfo:any,marketCap:any,tradingVolume:any,circulatingSupply:any,maxSupply:any,priceHistory:any) {
        try {
          // Extract relevant information from tokenInfo and pairInfo
        const isCodeVerified = tokenInfo?.data?.audit?.codeVerified;
        const isContractRenounced = tokenInfo?.data?.audit?.is_contract_renounced;
        const auditStatus = tokenInfo?.data?.audit?.status;
      
        const codeVerifiedWeight = isCodeVerified ? 2 : 0;
        const contractRenouncedWeight = isContractRenounced ? 1 : 0;
        const auditStatusWeight = auditStatus === 'OK' ? 3 : 0;
      
        const maxSupplyWeight = tokenInfo?.data?.metrics?.maxSupply > 1000000 ? 2 : 0;
        const totalSupplyWeight = tokenInfo?.data?.metrics?.totalSupply === 1000000 ? 2 : 0;
        const holdersWeight = tokenInfo?.data?.metrics?.holders > 50 ? 1 : 0;
        const txCountWeight = tokenInfo?.data?.metrics?.txCount > 100 ? 1 : 0;
      
        // Additional criteria for credibility based on the pairInfo
        const marketCapWeight = marketCap > 1000000 ? 3 : 0;
        const tradingVolumeWeight = tradingVolume > 1000 ? 2 : 0;
        const circulatingSupplyWeight = circulatingSupply > 10000 ? 2 : 0;
        const maxSupplyWeightFromPair = pairInfo?.data?.metrics?.maxSupply > 1000000 ? 2 : 0;
        const priceHistoryWeight = priceHistory?.length > 0 ? 1 : 0;
      
        // Calculate total weight
        const totalWeight =
          codeVerifiedWeight +
          contractRenouncedWeight +
          auditStatusWeight +
          maxSupplyWeight +
          totalSupplyWeight +
          holdersWeight +
          txCountWeight +
          marketCapWeight +
          tradingVolumeWeight +
          circulatingSupplyWeight +
          maxSupplyWeightFromPair +
          priceHistoryWeight;
      
        // Define credibility levels
        const credibilityLevels = {
          low: 0,
          medium: 5,
          high: 10,
        };
      
        // Determine credibility level based on total weight
        let credibility = 'low';
        if (totalWeight >= credibilityLevels.medium && totalWeight < credibilityLevels.high) {
          credibility = 'medium';
        } else if (totalWeight >= credibilityLevels.high) {
          credibility = 'high';
        }
      
        return  credibility
        } catch (error) {
          console.log(error)
        }
        
        }
        
        const contextValue = {
            tokenInfo,
            marketCap,
            tradingVolume,
            circulatingSupply,
            maxSupply,
            priceHistory,
            credibilityInfo,
  };
  
  const handleAddress = (event:any) => {
    console.log('handle',event.val)
  }
                    
           
    
    return (
        <>
    {/* <MyContext.Provider
     value={contextValues }
    >
      {children}
    </MyContext.Provider> */}
            <div className='main h-screen'>
                <div className="App">
                    <Headerfull name="John" />
                </div>
              <div className='h-full'>
              <div className='h-full flex justify-center items-center mx-auto px-2 sm:px-4 lg:px-4 w-11/12 py-0 text-center'>
                    <div className='md:mt-[-30px] max-w-[600px] mx-auto text-center'>
                        <p className='relative  font-grok leading-none pr-[30px] pl-[20px] mb-[-1px] sm:text-[100px] pb-[10px] text-[60px] font-bold text-gradient inline'>Analyse</p>
                        <p className='text-sm font-light leading-5 tracking-[.2px] text-[#E7E8DE] mt-[16px] mb-[20px]'>Objects's scanner rigorously evaluates key parameters to provide an initial investment risk analysis for your chosen token. It conducts extra checks to minimize false results and enhance accuracy</p>
                        <div className='relative'>
                            <div
                                className={`hidden sm:flex absolute top-[-1px] right-[69px] sm:w-[462px]  h-[57px] z-10 bg_g mb-[26px] inline-block p-[1px] back-gradient rounded-lg ${isFocused ? 'opacity-100' : 'opacity-0'}`}
                            ></div>
                            <div className='absolute right-[5px] md:right-[75px] top-[5px] z-[99] bg-[#1d1d22]'>       <Dropfinal /></div>
                            {/* <img className="h-[25px] w-[25px] absolute left-[10px] sm:left-[83px] top-[15px] z-30" src={f2} alt="eth" /> */}
                            <input
                            
                                className='border-[#81bac8] sm:border-1 pl-[15px]  z-20 relative input_token w-full  sm:w-[460px] h-[55px] text-[#8e8e91] bg-[#1d1d20] text-[15px] font-medium placeholder:text-[#8e8e91] px-[12px] py-[8px] border sm:border-[#343434] rounded-lg max-w-[460px]'
                                placeholder='Enter Token Address'
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                onChange={(e) => setInputValue(e.target.value)}

                            />
                        </div>
                        <div className='flex justify-center mt-[20px]'>
                  <button onClick={async () => { getTokenInfo(inputVal, chain?.name ? chain?.name : 'bsc') }} className='flex items-center w-43 w-[220px] md:max-w-[300px] md:w-[250px] sm:w-[300px] h-[46px]  md:h-[56px] bg-[#424242] border border-[#38a3bf] text-[#38a3bf] px-[20px] sm:px-[40px] py-[9px] text-base rounded-xl font-bold justify-center'>Check Credibility
                  
                  </button>
                </div>
                    </div>
                    {/* <div className="App">
                    <Footerlinksfixed name="John" />
                </div> */}
                </div> 
                </div>   
            </div>
        </>
    )
}
// export const exportValues = 'hassan';
export default Analyse2;
// export const useTokenContext = () => {
    // console.log('parent',object)
    // return useContext();
  // };