import React, { useMemo } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@rainbow-me/rainbowkit/styles.css';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import {Chain,ConnectButton,getDefaultWallets,lightTheme,midnightTheme,RainbowKitProvider,} from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig ,useConfig} from 'wagmi';
import {mainnet,polygon,optimism,arbitrum,base,sepolia,avalanche,bsc,zkSync,polygonZkEvm,linea,gnosis,fantom,moonriver,moonbeam,boba,aurora,fuse} from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public'
import { LiFiWidget, useWallet, WidgetConfig } from '@lifi/widget';
import { MyProvider, useTokenContext } from './Provider/useContext';
import { WalletProvider } from './Provider/useContext';
import eth from "./eth.svg"
import polygon_1 from "./Polygon.png";
import ethdrop from "./ethdrop.svg";
import ava from "./ava.jpeg";
import base1 from "./base1.svg";
import op from "./bri2.png";
import Ethereum from './Ethereum.svg';
import Fantom from './Fantom.png';
import Fuse from './Fuse.png';
import Gnosis from './Gnosis.png';
import Linea from './Linea.png';
import Moonbeam from './Moonbeam.png';
import Moonriver from './Moonriver.png';
import Optimism from './Optimism.png';
import Polygon from './Polygon.svg';
import zkSync1 from './zkSync1.png';
import Arbitrium from './Arbitrum.png';
import aurora1 from './aurora1.png';
import avalanche1 from './avalanche1.png';
import base_1 from './base_1.png';
import boba_1 from './boba_1.png';
import btc from './btc.png'
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


// Congigure all chains that are require also set manually icon of some chains

const { chains, publicClient } = configureChains(
  [ 
    
    {
      ...mainnet,
      iconUrl: ethdrop,
      coin:'ETH'
    },
    {
      ...polygon,
      iconUrl:polygon_1,

    },
    {
      ...optimism,
      iconUrl:Optimism,

    },
    {
      ...arbitrum,
      iconUrl:Arbitrium,

    },
    {
      ...base,
      iconUrl:base_1,

    },
    // {
    //   ...sepolia,
    //   iconUrl:''

    // },
    // {
    //   ...avalanche,
    //   iconUrl:avalanche1,

    // },
    {
      ...bsc,
      iconUrl:btc

    },
    {
      ...zkSync,
      iconUrl:zkSync1
      
    },
    {
      ...aurora,
      iconUrl:aurora1,
    },
    {
      ...boba,
      iconUrl:boba_1,
    },
    {
      ...fuse,
      iconUrl:Fuse
    },
    {
      ...moonbeam,
      iconUrl:Moonbeam
    },
    {
      ...moonriver,
      iconUrl:Moonriver,
    },
    {
      ...fantom,
      iconUrl:Fantom,
    }, {
      ...gnosis,
      iconUrl:Gnosis,
    }, {
      ...linea,
      iconUrl:Linea,
    }
  ],
  // [
  //   alchemyProvider({ apiKey: String(process.env.REACT_APP_ALCHEMY_ID) }),
  // ]
  [publicProvider()]
);

// END

// calling getdedault function to get chain from wagmi/chains and passing it into the configuration

const { connectors } = getDefaultWallets({
  appName: 'My RainbowKit App',
  projectId: '40c451b93164f3d94e983984bab1ce21',
  chains
});

// END

// calling createConfig function that pass connectors and publicclient  to make connect with blockchain

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient
})
console.log("🚀 ~ wagmiConfig:", wagmiConfig)



// END


root.render(
  <React.StrictMode>
    <WagmiConfig config={wagmiConfig}>
    <RainbowKitProvider chains={chains}  theme={midnightTheme({accentColor: '#7b3fe4',accentColorForeground: 'white',borderRadius: 'medium',fontStack: 'system',})} coolMode>
        <MyProvider  >
          <WalletProvider>
          <App />
          </WalletProvider>
      </MyProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  </React.StrictMode>
);

reportWebVitals();

