import React, { useState } from 'react';
import eth from "../images/eth.svg";
import eth1 from "../images/ethdrop.svg";
import eth2 from "../images/ethdrop1.svg";
import { useTokenContext } from '../Provider/useContext';
import { useSwitchNetwork } from 'wagmi';
function Dropfinal() {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };
  const { tokenContext, setTokenContext } = useTokenContext() as any;
  console.log("🚀 ~ Dropfinal ~ tokenContext:", tokenContext)

  const { chains, switchNetwork } = useSwitchNetwork();
  console.log("🚀 ~ Home ~ switchNetwork:", chains);

  function tokenInfo(address: any, imageUrl: any) {
    console.log('imgUrl', imageUrl)
    const obj = {
      
        name: address ,
      image:imageUrl}
    setTokenContext(obj);
    setIsDropdownVisible(false);
  }

  const defaultImage =  'https://raw.githubusercontent.com/lifinance/types/main/src/assets/icons/chains/bsc.svg';
  return (
    <div className="relative">
      <button
        id="dropdownDefaultButtonhead"
        data-dropdown-toggle="dropdownhead"
        className={`h_drop right-0 top-1 inline-flex items-center rounded-full ${
          isDropdownVisible ? 'bg-[#1d1d22] border border-[#343434] border-b-0' : 'bg-transparent'
        } text-white rounded-xl px-2.5 py-2.5 text-md font-medium flex gap-1 focus:outline-none focus-visible:outline-none `}
        type="button"
        onClick={toggleDropdown}
      >
    <p className=""><img className="h-5.5 w-5 " src={tokenContext?.image ? tokenContext?.image : eth} alt="Your Company" /></p><svg className="-mr-1 h-6 w-6 text-[#919192]" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd"></path></svg>
      </button>
      {isDropdownVisible && (
        <div
          id="dropdownhead"
          className="border border-[#343434] border-t-0 z-10 bg-[#1d1d22] divide-y divide-gray-100 rounded-b-xl shadow w-44 dark:bg-gray-700 w-[66px] top-[-22px] -translate-y-6 block"
          data-popper-placement="bottom"
          style={{
            position: 'absolute',
            inset: '0px auto auto 0px',
            margin: '0px',
            transform: 'translate(0px, 54px)',
          }}
        >
            <ul   className="text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButtonhead">
          {/* {chains && chains?.map((chain: any, index: any) => (
              <li className='mb-3' ><a href="#" className=""><img className="h-5.5 w-5 " onClick={() => tokenInfo(chain?.network? chain?.network : 'bsc' , chain?.iconUrl ? chain?.iconUrl :defaultImage )} src={chain?.iconUrl }  /></a></li>
          ))} */}
            {tokenContext?.name !== 'bsc' &&(
              <li className='py-2.5 px-2.5'>
              <a href="#" className="flex gap-3 text-white text-[16px] font-normal"><img onClick={() => tokenInfo('bsc',eth)} className="h-5.5 w-5 rounded-md" src={eth} alt="Your Company" />
              </a>
              </li>
            )}
            {tokenContext?.name !== 'ether' && (
              <li className='py-2.5 px-2.5'>
                <a href="#" className="flex gap-3 text-white text-[16px] font-normal"><img onClick={() => tokenInfo('ether', eth1)} className="h-5.5 w-5 rounded-md" src={eth1} alt="Your Company" /></a>
              </li>
            )}

            {tokenContext?.name !== 'polygon' && (
              <li className='py-2.5 px-2.5'>
                <a href="#" className="flex gap-3 text-white text-[16px] font-normal"><img onClick={() => tokenInfo('polygon', eth2)} className="h-5.5 w-5 rounded-md" src={eth2} alt="Your Company" /></a>
              </li>
            )}
            </ul>
        </div>
      )}
    </div>
  );
}

export default Dropfinal;
