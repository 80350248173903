// MyContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';

interface TokenContext {
  pairInfo:0,
  tokenInfo: Record<string, any>;
  marketCap: number;
  tradingVolume: number;
  circulatingSupply: number;
  maxSupply: number;
  priceHistory: any[];
  credibilityInfo: string;
}

// interface for wallet context
interface walletContext {
  isWalletConnect: boolean;
  isConnectModelOpen: boolean;
  chainDetail: {};
}

interface WalletContextProps {
  children: ReactNode;
}

const wallet: walletContext = {
  isWalletConnect: false,
  isConnectModelOpen: false,
  chainDetail:{},
}

const MyWalletContext = createContext<{ walletContext: walletContext; setWalletContext: any }>({
  walletContext: wallet,
  setWalletContext:()=>{},
})


const WalletProvider: React.FC<WalletContextProps> = ({ children }) => {
  const [walletContext, setWalletContext] = useLocalStorage("wallet-ctx", wallet);
  

return (
  <MyWalletContext.Provider value={{ walletContext, setWalletContext }}>
    {children}
  </MyWalletContext.Provider>
);
}; 

const useWalletContext = () => {
  return useContext(MyWalletContext);
};

// end

interface MyContextProps {
  children: ReactNode;
}

const initialTokenContext: TokenContext = {
  pairInfo:0,
  tokenInfo: {},
  marketCap: 0,
  tradingVolume: 0,
  circulatingSupply: 0,
  maxSupply: 0,
  priceHistory: [],
  credibilityInfo: '',
};

const MyContext = createContext<{ tokenContext: TokenContext; setTokenContext: any}>({
  tokenContext: initialTokenContext,
  setTokenContext: () => {},
});

const MyProvider: React.FC<MyContextProps> = ({ children }) => {
    const [tokenContext, setTokenContext] = useLocalStorage("token-ctx",initialTokenContext);
    

  return (
    <MyContext.Provider value={{ tokenContext, setTokenContext }}>
      {children}
    </MyContext.Provider>
  );
};

const useTokenContext = () => {
  return useContext(MyContext);
};

export { MyProvider, useTokenContext, MyContext,useWalletContext,MyWalletContext,WalletProvider };
