import React, { useState } from 'react'

const app_name = "app_name";

function useLocalStorage(key:string,initialData:any)   {
    const [state, setState] = useState(() => {
        const data = localStorage.getItem(`${app_name}-${key}`);
        if (data) {
            return JSON.parse(data) 
        }
        let init_data =initialData;
        if (typeof initialData === "function") {
            init_data= initialData();
        } 
        localStorage.setItem(`${app_name}-${key}`, JSON.stringify(init_data))
        return init_data
    }) 

    const updateState = (data:any) => {
        localStorage.setItem(`${app_name}-${key}`, JSON.stringify(data))
        setState(data)
    }
    // clear
  return [state,updateState]
}

export default useLocalStorage
